.MembersWorktimeSummaryTable .SummaryTable {
//   & .openDay,
//   & .openMonth {
//     color: rgba(var(--bs-secondary-rgb), 0.7);
//     border: none;
//     background-color: transparent;
//     &:active,
//     &:focus {
//       background-color: transparent;
//       outline: none;
//       border: none;
//     }
//     &:disabled {
//       color: transparent;
//     }
//     &:not([disabled]) {
//       @media (hover: hover) and (pointer: fine) {
//         &:hover {
//           color: var(--bs-primary);
//           &:hover {
//             background-color: transparent;
//             color: var(--bs-primary-variant-color);
//             transition: linear 0.3s;
//             & svg {
//               scale: 1.1;
//             }
//           }
//         }
//       }
//     }
//   }

  .row.disabled {
    color: gray !important;
  }

//   $sm-breakpoint: 576px;
//   @media (max-width: $sm-breakpoint) {
//     .openDay,
//     .openMonth {
//       padding-left: 3px !important;
//     }
//   }
  @media (max-width: 350px) {
    .openDay,
    .openMonth {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .totalTime {
      padding-left: 0px !important;
    }
  }
}
