.DayModal {
  .MemberDayTimeRecordTable > .SimpleCard,
  .DayRecordsTable > * > .SimpleCard {
    box-shadow: none;
  }
  .MemberDayTimeRecordTable > .SimpleCard,
  .MemberDayTimeRecordTable > .SimpleCard > .Grid > .Node > .WidgetCardHeader,
  .MemberDayTimeRecordTable
    > .SimpleCard
    > .Grid
    > .Node
    > .WidgetCardHeader
    > .Header 
    {
    padding-top: 0;
  }
  .MemberDayTimeRecordTable > .SimpleCard > .Grid > .Node > .TopExtras {
    top: -20px;
  }


  // .MemberDayTimeRecordTable > .SimpleCard > .Grid > .Node > .WidgetCardHeader > .Header .UserSelector {
  //   margin-top: -10px;
  // }
}
