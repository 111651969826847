.TextOptions {
  .AccordionItem.card {
    .AccordionItemHeader {
      .list-row-styled {
        border-radius: 6px;
        align-items: center;
      }
      .list-row-styled {
        padding-left: 0;
        padding-right: 0;
      }
      .fa-caret-left {
        transform: rotate(180deg);
        color: var(--secondary-color);
        cursor: pointer;
      }
      &.active .fa-caret-left {
        transform: rotate(270deg);
      }
    }
    .AccordionItemBody.card-body {
      //   padding-left: calc(var(--bs-gutter-x) * 0.5 + 0.5rem + 20px);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      .content {
        border-left: 1px solid var(--Table-head-border-color);
        padding-left: 10px;
      }
    }
  }

  .TextOption {
    margin-top: 10px;
    .title {
      color: var(--primary-color);
      font-weight: bold;
      text-align: left;
    }
  }

  $sm-breakpoint: 576px;
  @media (max-width: $sm-breakpoint) {
    .AccordionItemBody {
      padding-left: calc(var(--bs-gutter-x) * 0.25 + 0rem + 0px) !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      .content {
        border-left: none !important;
        padding-left: 0 !important;
        padding-bottom: 0.75rem;
      }
    }
  }
}
