.MemberMonthTimeRecordTable {
  .Table .Head .row .hours {
    padding-right: 25px;
  }
  .Table .Body .row .hours {
    // padding-left: 33px;
  }

  .openDay {
    color: rgba(var(--bs-secondary-rgb), 0.7);
    border: none;
    background-color: transparent;
  }
  .today {
    .date {
      font-weight: 500;
    }
  }
  .date {
    .small-screan {
      .full {
        display: inline;
      }
      .short {
        display: none;
      }
    }

    @container MemberMonthTimeRecordTable (max-width: 485px) {
      // .small-screan {
      //   .month {
      //     display: none;
      //   }
      // }
      .small-screan {
        // .full {
        //   display: none !important;
        // }
        // .short {
        //   display: inline;
        // }
        .month {
          display: none;
        }
      }
    }
    
    // @container MemberMonthTimeRecordTable (max-width: 350px) {
    //   .small-screan {
    //     .full {
    //       display: none !important;
    //     }
    //     .short {
    //       display: inline;
    //     }
    //   }
    // }
  }

  @container MemberMonthTimeRecordTable (max-width: 400px) {
    .openDay {
      padding-left: 0 !important;
    }
    .Table .Body .row .hours {
      // padding-left: 30px;
    }

  }
}
