.PendingMembersRequestsList .RequestsList {
  // & > .List > .Table > .Body > .ListItem.disabled {
    & .MonthRequestsItemWrapper.disabled {
    // opacity: 0.3;
    opacity: 0.6;
    pointer-events: none;
    animation: placeholder-glow 1.5s ease-in-out infinite;
  }
  & > .info {
    color: var(--secondary-color);
  }

  @media all and (max-width: 576px) {
    .Month {
        
    }
    & > .ListOfAccordions > .Table > .Body > .ListItem > .col > .Accordion > .AccordionItem.card >.accordion-collapse > .AccordionItemBody.card-body {
      padding-left: 10px !important;

      .Members {
        // border-left: none;
        .Member {
            
          // padding-left: calc(var(--bs-gutter-x) * 0.25 + 10px) !important;
        }
        .Requests .Request {
          // padding-left: calc(var(--bs-gutter-x) * 0.5 + 0.5rem + 10px) !important;
        }
      }
    }
  }
  $sm-breakpoint: 576px;

  @media (max-width: $sm-breakpoint) {
    .List > .Table > .Body .list-row-styled {
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      padding-right: calc(var(--bs-gutter-x) * 0.5);
    }
  }
}
