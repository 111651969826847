.ListOfAccordions {
  .AccordionItemBody {
    // padding-left: calc(var(--bs-gutter-x) * 0.5 + 0.5rem + 20px) !important;
    padding-left: calc(var(--bs-gutter-x) * 0.5 + 0.5rem + 12px) !important;
    
    $sm-breakpoint: 576px;
    @media (max-width: $sm-breakpoint) {
      // padding-left: calc(var(--bs-gutter-x) * 0.5 + 0.5rem + 0px) !important;
      padding-left: calc(var(--bs-gutter-x) * 0.5 + 0rem + 0px) !important;
    }

    & > div {
      border-left: 1px solid var(--Table-head-border-color);
    }
  }
}
