.MonthStatusUnresolvedDropdown {
    &.small {
        .dropdown-toggle {
          padding: 0.1rem 0.4rem;
          font-size: 0.8rem;
        }  
      }
    .dropdown-toggle.btn{
        color: var(--text-primary-invalid-on-surface);
        border-color: rgba(var(--text-primary-invalid-on-surface-rgb), 0.8);
        background-color: transparent;
     
        &:hover, &:active
        // &:focus 
        {
            background-color: transparent;
            color: var(--text-primary-invalid-on-surface);
            border-color: rgba(var(--text-primary-invalid-on-surface-rgb), 0.8);
        }            
    }
    .dropdown-toggle {
        &::after {
            display: block;
        }
        &::before, &::after  {
            content:none !important;
        }
    }
    
    //   padding: 0.25rem 0.5rem;
    //   font-size: 0.875rem;


    .dropdown-menu {
        // padding: 0.25rem 0;
        min-width: 5rem;
        .dropdown-item {
            // padding: 0.25rem 0.5rem;
            text-align: center;
            // font-size: 0.9rem;
            line-height: 1;
            // &:active {
            //     // background-color: var(--hover-on-surface-light);
            // }
        }
    } 
    
}