.MemberMonthTimeRecordTable {
    container-name: MemberMonthTimeRecordTable;
    container-type: inline-size;
    // min-width: 300px;

    .Modal {
        position: relative;
        .card {
            position: sticky;
            top: 5%;
        }
    }

}

.MemberMonthTimeRecordTableProvider {

    &.loading {
        pointer-events: none;
        // opacity: 0.5;
        opacity: 0.6;
        animation: placeholder-glow 1.5s ease-in-out infinite;
    }


}