.CategorySelector {
    width: 100%;
    .dropdown button.dropdown-toggle{
        min-height: 39px;
        height: 100%;
    }
    .dropdown,
    .dropdown-center {
      // max-width: 70%;
      .dropdown-toggle {
        background-color: transparent;
  
        // width: 100%;
        // text-overflow: ellipsis;
        // overflow: hidden;
        padding-right: 30px;
  
        // .category {
        //   max-width: 10%;
        //   color: var(--text-secondary-on-surface);
        //   .categoryName {
        //     text-overflow: ellipsis;
        //     overflow: hidden;
        //   }
        // }
        // .taskName {
        //   text-overflow: ellipsis;
        //   overflow: hidden;
        // }
  
        // &:disabled {
        //   color: var(--text-disabled-on-surface);
        // }
  
        // @at-root .CategorySelector-select#{&}{
        //   // form-select
        //   color: var(--bs-body-color);
        //   border: var(--bs-border-width) solid var(--bs-border-color);
        //   border-radius: var(--bs-border-radius);
        //   background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon,none);
        //   background-repeat: no-repeat;
        //   background-position: right 0.75rem center;
        //   background-size: 16px 12px;
        //   appearance: none;
  
        //   text-align: start;
        //   &::after {
        //     content: none;
        //   }
        // }
        // @at-root .CategorySelector-dropdown#{&}{
        //   color: var(--primary-color);
        //   border-radius: unset;
        //   border: unset;
        //   border-bottom: 1px solid var(--secondary-color);
        //   border-bottom: 1px solid var(--secondary-color);
        // }
      }
      .dropdown-menu {
        border: 1px solid var(--border-color);
        .dropdown-header {
          color: var(--text-secondary-on-surface);
          cursor: default;
        }
        .dropdown-item {
          color: var(--text-primary-on-surface);
          &:hover {
            background-color: var(--hover-on-surface-light);
          }
          &:active {
            background-color: var(--hover-on-surface-dark);
          }
        }
      }
    }
    &.CategorySelector-select .dropdown-toggle {
      width: 100%;
      // form-select
      color: var(--bs-body-color);
      border: var(--bs-border-width) solid var(--bs-border-color);
      border-radius: var(--bs-border-radius);
      background-image: var(--bs-form-select-bg-img),
        var(--bs-form-select-bg-icon, none);
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 16px 12px;
      appearance: none;
  
      text-align: start;
      &::after {
        content: none;
      }
    }
    &.CategorySelector-dropdown .dropdown-toggle {
      color: var(--primary-color);
      border-radius: unset;
      border: unset;
      border-bottom: 1px solid var(--secondary-color);
      border-bottom: 1px solid var(--secondary-color);
    }

    // &.CategorySelector-combobox {
    //     .rbt-close-content {
    //         display: none;
    //     }
    // }
    .CategorySelectorPlaceholder {
      &.placeholder,
      &.placeholder-wave,
      &.placeholder-glow {
        background-color: transparent;
        .btn {
          background-color: transparent;
          color: var(--primary-color);
          border-radius: unset;
          border: unset;
          border-bottom: 1px solid var(--secondary-color);
          border-bottom: 1px solid var(--secondary-color);
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 70%;
        }
      }
    }
  }
  