.DayRecordsTable {
    container-name: DayRecordsTable;
    container-type: inline-size;
    // min-width: 300px;

    .WidgetCardBody {
        padding-top: calc(var(--bs-card-spacer-y)  / 2);
      }
}

