.ActiveMembersList {
  .title {
    color: var(--primary-color);
    text-align: center;
    // text-decoration: underline;
  }
  .Header {
    // .refresh {
    //     color: var(--secondary-color);
    //     .btn {
    //       padding: 0;
    //         background-color: transparent;
    //         border: none;
    //         vertical-align: unset;
    //         height: 100%;
    //         svg {
    //           color: var(--secondary-color);
    //           fill: var(--secondary-color);
    //           margin-right: 1px;
    //           position: unset;
    //           padding: 1px;
    //           vertical-align: -0.125em !important;

    //           &:hover {
    //             color: var(--primary-color);
    //           }
    //         }
    //     }
    //     &:hover {
    //       transition: linear 0.3s;
    //       color: var(--primary-color);
    //       & svg {
    //         scale: 1.1;
    //       }
    //     }
    //   }

    .refresh {
      .btn {
        color: rgba(var(--bs-secondary-rgb), 0.7);
        border: none;
        background-color: transparent;
        &:active,
        &:focus,
        &:hover {
          background-color: transparent;
          outline: none;
          border: none;
        }
        &:disabled {
          color: transparent;
        }
      }
      &:hover {
        .btn:not([disabled]) {
          color: var(--bs-primary);
          &:hover {
            background-color: transparent;
            color: var(--bs-primary-variant-color);
            transition: linear 0.3s;
            & svg {
              scale: 1.1;
            }
          }
        }
      }
    }
    
    .arrow {
      color: var(--border-color-dark);
      padding: 0;
      &:hover {
        color: var(--primary-color);  
      }
      $paddingX: 2px;
      &.arrow-left {
        padding-left: $paddingX;
        padding-left: calc(0.15rem + $paddingX);
        margin-right: -3px;

      }
    }
  }
}
