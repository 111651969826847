.DayRecordsTable .TaskTrackingDataTable {

  .label-manual {
    opacity: 0.5;
  }
  $screen-size-small: 435px;
  @container (max-width: #{$screen-size-small}) {
    .billableTimerecords, .unverifiedTimerecords {
      padding-left: 0;
      padding-right: 0;
    }
    // font-size: 0.9rem;
    & .Table .Head {
      // font-size: 0.795rem;
    }
  }
  & .Table .Body .taskName.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .billableTimerecords {
  }
  .unverifiedTimerecords {
    .Table {
      &,
      & > *,
      & > * > * {
        --Table-backgroung-color: transparent;
        --Table-head-color: var(--text-primary-invalid-on-surface);
        --Table-head-border-color: rgba(
          var(--text-secondary-invalid-on-surface-rgb),
          0.9
        );
        --Table-color: var(--text-primary-invalid-on-surface);
        --Table-active-color: var(--text-primary-invalid-on-surface);
        --Table-active-background: rgba(
          var(--text-primary-invalid-on-surface-rgb),
          0.1
        );
        --Table-hover-color: var(--text-primary-invalid-on-surface);
        --Table-hover-background: rgba(
          var(--text-primary-invalid-on-surface-rgb),
          0.055
        );
      }

      &.disabled {
        &,
        & > *,
        & > * > * {
          --Table-backgroung-color: transparent;
          --Table-head-color: var(--text-primary-invalid-on-surface);
          --Table-head-border-color: rgba(
            var(--text-secondary-invalid-on-surface-rgb),
            0.9
          );
          --Table-color: var(--text-disabled-on-surface);
          --Table-active-color: var(--text-disabled-on-surface);
          --Table-active-background: rgba(var(--text-disabled-on-surface), 0.1);
          --Table-hover-color: var(--text-disabled-on-surface);
          --Table-hover-background: rgba(
            var(--text-disabled-on-surface-rgb),
            0.055
          );
        }
      }

      .Head {
        .title {
          color: var(--text-primary-invalid-on-surface);
          // font-size: 0.7rem;
          font-weight: normal;
          position: relative;
          bottom: -2px;
          span {
            // background-color: white;
          }
        }
      }
    }
  }
}
