.ModalDeletedUsers {
  .RoleUsersList {
    .User {
      color: var(--text-secondary-on-surface);
    }
    .UserInfo.List  .Table > .Body > .row {
      color: var(--text-secondary-on-surface);
    }
}
}
