.TimeInput {
    &>.col {
        margin-bottom: 0.75rem;
    }
    .input-group {
        flex-wrap: nowrap;
        input[type=number] {
            min-width: 85px;
            // min-width: 60px;
            // &:invalid, &.is-invalid {
            //     min-width: 85px;
            // }
            &.is-invalid {
                padding-right: 0.75rem;
                &:not(:disabled) {
                    background-image: none;
                }
            }
        }
        .input-group-text {
            width: 41px;
            text-align: center;
            justify-content: center;
        }
    }
}