.PendingMembersRequestsList .RequestsList .Requests .Request {
  // padding-right: calc(var(--bs-gutter-x) * 0.5 + 0.5rem + 40px) !important;
  .label {
    font-size: 0.85rem;
    color: var(--secondary-color);
    color: rgba(var(--primary-color-rgb), 0.55);
  }

  .accent {
    // text-decoration: underline;
    font-weight: 500;
  }
  $sm-breakpoint: 576px;

  .actions .TooltipButton {
    padding-right: 0.55rem;
    padding-left: 0.55rem;
    @media (max-width: $sm-breakpoint) {
      padding-right: 0.25rem;
      padding-left: 0.25rem;
    }
  }
  .month-link {
    color: inherit;
  }

  &:hover {
    .date-info .date {
      // text-decoration: underline;
      // font-weight: 500;
    }
  }

  @media (max-width: 400px) {
    .date-label {
      display: none !important;
    }
    .date-info .date{
      // text-decoration: underline;
      // border-bottom: solid 0.5px black;
      font-weight: 500;
    }
  }

  &.warning {
    background-color: rgba(var(--text-primary-invalid-on-surface-rgb), 0.05);
    &:hover {
      background-color: rgba(var(--text-primary-invalid-on-surface-rgb), 0.1);
    }
  }
}
