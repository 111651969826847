.List {
  $sm-breakpoint: 576px;
  & > .Table > .Body {
    & > .row.ListItem {
      color: var(--text-primary-on-surface);
      min-height: 52px;
      padding-left: 0;
      padding-right: 0;
      padding: 0;
      &:hover {
        background-color: transparent;
      }
      &.active {
        background-color: #2d7b880f;
      }
      // &.public {
      //   & > .col > .Accordion > .AccordionItem > .AccordionItemHeader {
      //     color: var(--text-disabled-on-surface);
      //     // &:hover {
      //     //   color: var(--primary-color);
      //     // }
      //   }
      // }
      & > .col {
        // padding-left: calc(var(--bs-gutter-x) * .5 + 0.5rem);
        // padding-right: calc(var(--bs-gutter-x) * .5 + 0.5rem);
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  // &.hover > .Table > .Body > .row.ListItem {
  //   &:hover {
  //     background-color: var(--Table-hover-background);
  //   }
  // }

  & > .Table > .Body .list-row-styled {
    width: 100%;
    height: 100%;
    padding-left: calc(var(--bs-gutter-x) * 0.5 + 0.5rem);
    padding-right: calc(var(--bs-gutter-x) * 0.5 + 0.5rem);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: unset;
    cursor: pointer;
    min-height: 52px;

    @media (max-width: 576px) {
      & {
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        padding-right: calc(var(--bs-gutter-x) * 0.5);
      }
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        @media all and (min-width: 576px) {
          background-color: var(--Table-hover-background);
        }
      }
    }
    &.active {
      font-weight: bold;
    }
    .list-label-opened {
      display: none;
      @media (max-width: ($sm-breakpoint - 1)) {
        display: none !important;
      }
      color: var(--secondary-color);
    }

    & .list-row-btn {
      color: rgba(var(--secondary-color-rgb), 0.7);
      border: none;
      background-color: transparent;
      &:active,
      &:focus,
      &:hover {
        background-color: transparent;
        outline: none;
        border: none;
      }
      // &:disabled {
      //     // color: rgba(var(--bs-secondary-rgb), 0.3);
      //   }
      @media all and (max-width: 576px) {
        color: rgba(var(--primary-color-rgb), 0.8);
        padding-left: calc(var(--bs-btn-padding-x) * 0.5) ;
        padding-right: calc(var(--bs-btn-padding-x) * 0.5) ;
      }
    }
    .list-row-badge {
      cursor: pointer;
      font-size: 0.65em;
      vertical-align: middle;
      opacity: 0.8;
      background-color: var(--secondary-color) !important;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .list-active-arrow {
      width: fit-content;
      .fa-caret-left {
        transform: rotate(180deg);
        color: var(--secondary-color);
        cursor: pointer;

        @media (max-width: $sm-breakpoint) {
          margin-left: 0 !important;
        }
      }
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover,
      &.active {
        .list-row-btn {
          color: var(--primary-color);
          &:hover {
            background-color: transparent;
            color: var(--primary-variant-color);
            transition: linear 0.3s;
            & svg {
              scale: 1.1;
            }
          }
          &:disabled {
            color: rgba(var(--bs-secondary-rgb), 0.3);
            &:hover {
              & svg {
                scale: none;
              }
            }
          }
        }

        .list-row-badge {
          background-color: var(--primary-color) !important;
        }
      }
    }
    &.active {
      .list-active-arrow {
        .fa-caret-left {
          transform: rotate(270deg);
        }
      }
      .list-label-opened {
        display: block;
      }
    }
  }
}
