.TimeCount {
    font-size: 35px;
    color: var(--text-primary-on-surface);
    .days {
        color: var(--text-primary-invalid-on-surface);
        margin-right: 10px;
        .day-sign{
            color: var(--text-secondary-invalid-on-surface);
            font-size: 0.6em;
        }
    }
}