.WorkTimeSummaryTable {
  // .Table .Head .row .hours {
  //   padding-left: 50px !important;
  //   padding-right: 50px !important;
  // }
  .Table .Head .row .hours {
    padding-right: 25px;
  }
  .Table .Body .row .hours {
    // padding-left: 33px;
  }
  // .row {
  //   & .openDay {
  //     color: rgba(var(--bs-secondary-rgb), 0.7);
  //     border: none;
  //     background-color: transparent;
  //     &:active,
  //     &:focus,
  //     &:hover {
  //       background-color: transparent;
  //       outline: none;
  //       border: none;
  //     }
  //     &:disabled {
  //       color: transparent;
  //     }
  //   }
  // }
  // .row {
  //   &:hover {
  //     & .openDay:not([disabled]) {
  //       color: var(--bs-primary);
  //       &:hover {
  //         background-color: transparent;
  //         color: var(--bs-primary-variant-color);
  //         transition: linear 0.3s;
  //         & svg {
  //           scale: 1.1;
  //         }
  //       }
  //     }
  //   }
  // }

  .date {
    .small-screan {
      .full {
        display: inline;
      }
      .short {
        display: none;
      }
    }
    @container MonthRecordsTable (max-width: 485px) {
      .small-screan {
        .month {
          display: none;
        }
      }
    }

    // @container MonthRecordsTable (max-width: 350px) {
    //   .small-screan {
    //     .full {
    //       display: none;
    //     }
    //     .short {
    //       display: inline;
    //     }
    //   }
    // }

    .weekday {
      color: var(--secondary-color);
      font-weight: 300;
      min-width: 20px;
      display: inline-block;
      text-align: left;
      font-size: 10px;
      // vertical-align: middle;
      &.weekday-mobile {
        font-size: 9px;
        // display: none;
      }
    }
  }
  .today {
    .date {
      font-weight: 500;
      .weekday {
        font-weight: 500;
      }
    }
  }

  @container MonthRecordsTable (max-width: 400px) {
    .openDay {
      padding-left: 0 !important;
    }
    .Table .Body .row .hours {
      // padding-left: 30px !important;
    }
  }


}

@container MonthRecordsTable (max-width: 400px) {
  .DayStatusDropdownnnnnnn.submitted > .dropdown-toggle {
    font-size: 0.8rem;
  }
}
