.Card .Expand {
  position: absolute;
  // top: 1px;
  top: 4px;
  left: 9px;
  z-index: inherit;

  & button {
    border: none;
    padding: 1px;
    background: transparent;
    color: var(--secondary-color);
    color: var(--selected-on-surface-light);
    &:hover, &:active {
      color: var(--primary-color);
      color: var(--secondary-color);
      background: transparent;
    }
  }
}

.Card .Modal .Expand {
  z-index: calc(var(--zindex-modal) + 1);
}
