.MonthRecordsTable .Header {
  .month-status {
    flex-direction: row;
    flex-wrap: wrap;
    @container MonthRecordsTable (max-width: 400px) {
      flex-direction: column;
      // .status-info {
      //   width: 100%;
      //   .DayStatusDropdownnn {
      //     width: 100%;
      //   }
      // }
    }
  }
  .time-totals {
    color: var(--primary-color);
    flex-direction: row;
    flex-wrap: wrap;
    @container MonthRecordsTable (max-width: 576px) {
      justify-content: start !important;
      flex-direction: column-reverse;
      //   // .status-info {
      //   //   width: 100%;
      //   //   .DayStatusDropdownnn {
      //   //     width: 100%;
      //   //   }
      //   // }
      .total-time,
      .doc-time {
        border: none !important;
      }
      .doc-time {
        .hours {
          padding-left: 3px;
        }
      }
    }
    @container MonthRecordsTable (max-width: 300px) {
      .total-time,
      .doc-time {
        .name {
          display: none;
        }
      }
      .doc-time {
        .hours {
          padding-left: 0px;
        }
      }
      .total-time {
        .hours {
          padding-left: 1px;
        }
      }
    }
  }
  .openYear {
    color: var(--secondary-color);
    .btn {
      padding: 0;
      background-color: transparent;
      border: none;
      vertical-align: unset;
      height: 100%;
      svg {
        color: var(--secondary-color);
        fill: var(--secondary-color);
        margin-right: 1px;
        position: unset;
        padding: 1px;
        vertical-align: -0.125em !important;

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
  .downloadTimesheet {
    color: rgba(var(--bs-secondary-rgb), 0.7);
    border: none;
    background-color: transparent;
    transition: linear 0.3s;
    &:hover {
      color: var(--primary-color);
      transition: linear 0.3s;
      // scale: 1.01;

      svg {
        // scale: 1.02;
        background-color: transparent;
        color: var(--bs-primary-variant-color);
        transition: linear 0.3s;
      }
    }
  }
}
