button.MonthStatusUnresolvedButton {
  &.btn {
    color: var(--text-primary-invalid-on-surface);
    border-color: rgba(var(--text-primary-invalid-on-surface-rgb), 0.8);
    background-color: transparent;
    &:hover {
    background-color: transparent;

    }
    &:active {
      background-color: transparent;
      color: var(--text-primary-invalid-on-surface);
      border-color: rgba(var(--text-primary-invalid-on-surface-rgb), 0.8);
    }
  }
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  &.small {
    padding: 0.1rem 0.4rem;
    font-size: 0.8rem;
    }
}
