@use "../../assets/styles/theme.scss" as theme;

.SimpleCard {
  &.border {
    border: var(--box-border-on-surface) !important;
  }
  // height: 100%;
  width: 100%;
  box-shadow: rgb(209, 209, 209) 0px 0px 5px 1px;
  box-shadow: var(--box-shadow);

  border: none;
  padding: 15px 4px 10px 4px;
  background-color: var(--surface-color);
}
