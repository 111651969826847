.TasksList .CategoriesList .Category {
  $sm-breakpoint: 576px;

  &.public {
    color: var(--text-disabled-on-surface);
  }

  .public-info {
    color: var(--secondary-color);
    align-self: center;
  }
  .categoryName {
    // max-width: 70%;
    max-width: 100%;
    // width: 100%;
    &.ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    overflow-y: auto;
    max-height: calc(var(--bs-body-line-height) * var(--bs-body-font-size) * 2);
    @media (min-width: $sm-breakpoint) {
      max-width: calc(100% - 80px);
    }
  }
  .fa-caret-left {
    transform: rotate(180deg);
    color: var(--secondary-color);
    cursor: pointer;
  }
  .count {
    display: block;
    @media (max-width: ($sm-breakpoint - 1)) {
      display: none !important;
    }
    cursor: default;
  }
  .open {
    display: none;
    @media (max-width: ($sm-breakpoint - 1)) {
      display: none !important;
    }
    color: var(--secondary-color);
  }
  &.active {
    font-weight: bold;
    .fa-caret-left {
      transform: rotate(270deg);
      // color: var(--primary-color);
    }
    .count {
      display: none;
    }
    .open {
      display: block;
    }
  }
}
