.TooltipDropdownButton { 
    &.no-arrow {
        .dropdown-toggle::after {
            content: none;
        }  
    }  

    .dropdown-menu {
        // padding: 0.25rem 0;
        min-width: 5rem;
        .dropdown-item {
            // padding: 0.25rem 0.5rem;
            text-align: center;
            line-height: 1;
            // &:active {
            //     // background-color: var(--hover-on-surface-light);
            // }
        }
    }

    &.sm {
        .dropdown-menu .dropdown-item {
            font-size: 0.9rem;
        }
    }
}


