@use "../../assets/styles/variables.scss" as global;
.Grid {
  display: grid;
  width: 100%;
  height: 100%;
  z-index: global.$z-index-forth;
  grid-template-columns: repeat(1, minmax(250px, 1fr));
  .Node {
    display: block;
    grid-column: 1;
    grid-row: 1;
    z-index: global.$z-index-forth;
    width: 100%;
    max-width: 100%;

    // overflow: auto;

    &.main {
      z-index: global.$z-index-third;
    }
  }
}
