.MembersMonthlyTimesheetsTable {
    .copy-btn {
        
        &.highlighted {
            animation: colorFade 1.4s forwards;

        }
        @keyframes colorFade {
            0% {
                color: var(--primary-color);
            }
            25% {
                color: var(--primary-color);
            }
            100% {
                color: var(--secondary-color);
            }
        }
        .copy-check-mark {
          visibility: hidden;
          color: var(--primary-color);
         }
      
        .copy-check-mark.visible {
          visibility: visible;
          opacity: 1;
        }
      
        .copy-check-mark.quick-show {
          visibility: visible;
          opacity: 1;
          animation: fadeOut 1.5s forwards;
        }
      
        @keyframes fadeOut {
          0% {
            opacity: 1;
          }
          35% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
    }
}
