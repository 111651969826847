.Timer .TimerContent {
  .started {
    color: var(--secondary-color);
    color: var(--text-disabled-on-surface);
    // color: var(--text-secondary-on-surface);
    position: relative;
    top: 15px;
    margin-top: -15px;
  }
  .disabled-info {
    color: var(--primary-color);
    .additional-info {
      color: var(--secondary-color);
    }
  }

  @container Timer (max-width: 450px) {
    .TimeCount {
        font-size: 32px;
    }
  }
  @container Timer (max-width: 400px) {
    .TimeCount {
        font-size: 30px;
    }
  }
  @container Timer (max-width: 375px) {
    .TimeCount {
        font-size: 28px;
        .days {
            font-size: 20px;
            margin-right: 5px;
        }
    }
  }
  @container Timer (max-width: 350px) {
    .TimeCount {
        font-size: 26px;
        overflow-wrap: anywhere;
        .days {
            font-size: 20px;
            margin-right: 5px;
            
        }
        .count {
            white-space: nowrap;
        }
    }
  }
}
