.TasksList .CategoriesList .CategoryTasks .Task {
    .taskName {
        // max-width: 30%;
        &.ellipsis{
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        overflow-y: auto;
        max-height: calc(var(--bs-body-line-height) * var(--bs-body-font-size) * 2);
      }
      .editTask {
        color: rgba(var(--bs-secondary-rgb), 0.7);
        border: none;
        background-color: transparent;
        &:active,
        &:focus,
        &:hover {
          background-color: transparent;
          outline: none;
          border: none;
        }
      }

      &:hover {
        .editTask {
          color: var(--bs-primary);
          &:hover {
            background-color: transparent;
            color: var(--bs-primary-variant-color);
            transition: linear 0.3s;
            & svg {
              scale: 1.1;
            }
          }
        }
      }
}