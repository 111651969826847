.TasksList .CategoriesList {
  $sm-breakpoint: 576px;
  .List {
    .ListItem {
      .Accordion .AccordionItem {
        
        .AccordionItemHeader {
          .Category{

            // min-height: 52px;
          }
        }
        .AccordionItemBody {
          padding-left: calc(var(--bs-gutter-x) * 0.5 + 0.5rem + 20px);
          padding-right: calc(var(--bs-gutter-x) * 0.5 + 0.5rem);
          // padding-left: 20px;
        }
      }
    }
  }
}
