@use "../../assets/styles/variables.scss" as global;
.Card {
    $card-z-index: global.$z-index-forth;
    // z-index: $card-z-index;
    // tmp
    height: 100%; 

    position: relative;

    &.d-grid {
        .grid-1-1 {
            z-index: $card-z-index;
            grid-column: 1;
            grid-row: 1;
        }
    }
}