.DatePicker {
  margin-right: 0.35rem !important;
  margin-left: 0.25rem !important;
  &:has(> div > .arrow.arrow-left)  {
    // background-color: red;
    margin-left: 0.1rem !important;
  }
  &:has(> div > .arrow.arrow-right)  {
    // background-color: red;
    margin-right: 0.2rem !important;
  }
  .arrow {
    // color: var(--secondary-color);
    // opacity: 0.6;
    color: var(--border-color-dark);
    padding: 0;
    line-height: 0;
    &:hover {
      color: var(--primary-color);
      // opacity: 1;

    }
    // background-color: yellow;
    $paddingX: 2px;
    &.arrow-left {
      padding-left: $paddingX;
      padding-left: calc(0.15rem + $paddingX);
      margin-right: 1px;

    }
    &.arrow-right {
    padding-right: $paddingX;
    padding-right: calc(0.15rem + $paddingX );

      
    }
    &:disabled {
      color: var(--border-color)
    }
  }
  &:has(.react-datepicker__tab-loop)
  // , &:focus-within, &:focus, &:active 
  {
    border-bottom: 1px solid var(--secondary-color);
    & .react-datepicker-wrapper .react-datepicker__input-container button svg.react-datepicker__calendar-icon {
      color: var(--primary-color);
    }
    
  }
  & .react-datepicker-wrapper:hover {
    & .react-datepicker__input-container button svg.react-datepicker__calendar-icon {
      color: var(--primary-color);
    }
  }

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      & > span {
        white-space: nowrap;
      }
      cursor: pointer;
      button {
        padding: 0;
        background-color: transparent;
        border: none;
        vertical-align: unset;
        height: 100%;
        
        svg.react-datepicker__calendar-icon {
          color: var(--secondary-color);
          fill: var(--secondary-color);
          margin-right: 1px;
          position: unset;
          padding: 1px;
          vertical-align: -0.125em !important;
  
          &:hover {
            color: var(--primary-color);
          }
        }
      }
      svg.react-datepicker__calendar-icon {
        color: var(--secondary-color);
        fill: var(--secondary-color);
        margin-right: 1px;
        position: unset;
        padding: 1px;
        vertical-align: -0.125em !important;

        &:hover {
          color: var(--primary-color);
        }
      }
      span.react-datepicker__calendar-text {
        border: none;
        color: var(--primary-color);
        outline-color: var(--secondary-color);
        width: 5.2rem;
        &:read-only,
        &:disabled {
          background-color: transparent;
          outline: none;
        }
        padding-left: 0.1rem;
        padding-right: 0.1rem;

        &:hover {
          color: var(--primary-variant-color);
        }
      }
    }
  }

  .react-datepicker-popper {
    .react-datepicker {
      background-color: var(--surface-color);
      border-color: var(--secondary-color);
      box-shadow: 0 1px 5px 0px var(--box-shadow-color);
      .react-datepicker__triangle {
        &::before {
          border-bottom-color: var(--secondary-color);
        }
        &::after {
          border-bottom-color: var(--background-color);
        }
      }

      .react-datepicker__month-container {
        .react-datepicker__header {
          border-bottom-color: var(--secondary-color);
          background-color: var(--hover-on-surface-lighter);

          &.react-datepicker-year-header {
            color: var(--primary-variant-color);
          }
          .react-datepicker__current-month,
          .react-datepicker-time__header,
          .react-datepicker-year-header {
            // color: var(--text-primary-on-surface);
            color: var(--primary-variant-color);
          }
          .react-datepicker__day-name {
            color: var(--primary-color);
          }

          &:not(.react-datepicker__header--has-time-select) {
            border-top: none;
          }
        }
        .react-datepicker__month {
          .react-datepicker__month-wrapper {
            .react-datepicker__month-text {
              width: 5rem;
              color: var(--text-primary-on-surface);
              &:hover {
                background-color: var(--hover-on-surface-lighter);
                box-shadow: 0 0px 0px 1px var(--secondary-color);
              }
              &.react-datepicker__month-text--disabled {
                color: var(--text-disabled-on-surface);
                &:hover {
                  background-color: transparent;
                  box-shadow: 0 0px 0px 1px transparent;
                }
              }
            }
            .react-datepicker__month-text--today {
              color: var(--text-primary-on-surface);
            }
            .react-datepicker__month-text--keyboard-selected {
              background: transparent;
            } 
            .react-datepicker__month-text--selected {
              background-color: var(--secondary-color);
              background-color: var(--primary-color);
              color: var(--surface-color);
              outline: none;
              &:hover {
                background-color: var(--primary-variant-color);
              }
            }
          }
          .react-datepicker__week {
            .react-datepicker__day {
              color: var(--text-primary-on-surface);
              background-color: transparent;
              &:hover {
                background-color: var(--hover-on-surface-lighter);
                box-shadow: 0 0px 0px 1px var(--secondary-color);
              }
              &.react-datepicker__day--disabled {
                color: var(--text-disabled-on-surface);
                &:hover {
                  background-color: transparent;
                  box-shadow: 0 0px 0px 1px transparent;
                }
              }
            }
            .react-datepicker__day--today {
              color: var(--text-primary-on-surface);
            }
            .react-datepicker__day--selected {
              background-color: var(--secondary-color);
              background-color: var(--primary-color);
              color: var(--surface-color);
              outline: none;
              &:hover {
                background-color: var(--primary-variant-color);
              }
            }
          }
        }
      }

      .react-datepicker__year--container {
        .react-datepicker__header {
          border-bottom-color: var(--secondary-color);
          background-color: var(--hover-on-surface-lighter);
          &.react-datepicker-year-header {
            color: var(--primary-variant-color);
          }
        }
        .react-datepicker__year {
          .react-datepicker__year-wrapper {
            max-width: 255px;
            .react-datepicker__year-text {
              width: 5rem;
              color: var(--text-primary-on-surface);
              &:hover {
                background-color: var(--hover-on-surface-lighter);
                box-shadow: 0 0px 0px 1px var(--secondary-color);
              }

              &.react-datepicker__year-text--disabled {
                color: var(--text-disabled-on-surface);
                &:hover {
                  background-color: transparent;
                  box-shadow: 0 0px 0px 1px transparent;
                }
              }
            }
            .react-datepicker__year-text--today {
              color: var(--text-primary-on-surface);
            }
            .react-datepicker__year-text--selected {
              background-color: var(--secondary-color);
              background-color: var(--primary-color);
              color: var(--surface-color);
              outline: none;
              &:hover {
                background-color: var(--primary-variant-color);
              }
            }
          }
        }
      }
    }
  }
}
