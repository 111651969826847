.PendingMembersRequestsList .RequestsList .Member {
  $sm-breakpoint: 576px;
  padding-left: calc(var(--bs-gutter-x) * 0.25) !important;
  // padding-right: calc(var(--bs-gutter-x) * 0.5 + 0.5rem + 20px) !important;
  color: var(--primary-color);

  .name {
    margin-left: 0.25rem;
  }
  .fa-caret-left {
    transform: rotate(180deg);
    color: var(--secondary-color);
    cursor: pointer;
  }
  .count {
    display: block;
    @media (max-width: ($sm-breakpoint - 1)) {
      display: none !important;
    }
    cursor: default;
  }
  .open {
    display: none;
    @media (max-width: ($sm-breakpoint - 1)) {
      display: none !important;
    }
    color: var(--secondary-color);
  }
  &.active {
    font-weight: bold;
    .fa-caret-left {
      transform: rotate(270deg);
      // color: var(--primary-color);
    }
    .count {
      display: none;
    }
    .open {
      display: block;
    }
  }

  .actions .TooltipButton {
    padding-right: 0.55rem;
    padding-left: 0.55rem;
    @media (max-width:  $sm-breakpoint) {
      padding-right: 0.25rem;
      padding-left: 0.25rem;
    }
  }

  .fa-extra-padding {
    padding-left: 2px;
    padding-right: 2px;
  }

  &.warning {
    &:hover {
      background-color: rgba( var(--text-primary-invalid-on-surface-rgb), 0.055 );
    }
    & > div > .open {
      color: rgba(var(--text-primary-invalid-on-surface-rgb), 0.6 );
    }
    & > div > .count > .badge {
      background-color: rgba(var(--text-primary-invalid-on-surface-rgb), 0.55 ) !important;
    }
    &:hover {
      & > div > .count > .badge {
      background-color: rgba(var(--text-primary-invalid-on-surface-rgb), 0.8 ) !important;
      }
    }
  }
}
