.UserSelector {
  .dropdown,
  .dropdown-center {
    // max-width: 70%;
    .dropdown-toggle {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      height: 100%;
      background-color: transparent;

      // width: 100%;
      // text-overflow: ellipsis;
      // overflow: hidden;
      padding-right: 30px;
      min-height: calc(
        var(--bs-btn-padding-y) * 2 + var(--bs-btn-line-height) *
          var(--bs-btn-font-size) + 1px
      );
      max-height: calc(
        var(--bs-btn-padding-y) * 2 + 2 *
          (var(--bs-btn-line-height) * var(--bs-btn-font-size) + 1px)
      );
    }
    .dropdown-menu {
      max-width: 100vw;
      border: 1px solid var(--border-color);
      max-height: 300px;
      overflow-y: auto;
      overflow-x: auto;
      .dropdown-header {
        color: var(--text-secondary-on-surface);
        cursor: default;
        & .public {
          color: var(--text-disabled-on-surface);
        }
      }
      .dropdown-item {
        color: var(--text-primary-on-surface);
        &:hover {
          background-color: var(--hover-on-surface-light);
        }
        &:active {
          background-color: var(--hover-on-surface-dark);
        }
      }
    }
  }

  &.select {
    .dropdown .dropdown-toggle {
      display: inline-flex;
      align-items: center;
      justify-content: left;
      min-height: calc(
        var(--bs-btn-padding-y) * 2 + var(--bs-btn-line-height) *
          var(--bs-btn-font-size) + 1px
      );
      max-height: calc(
        var(--bs-btn-padding-y) * 2 + 2 *
          (var(--bs-btn-line-height) * var(--bs-btn-font-size) + 1px) + 2px
      );

      width: 100%;
      // form-select
      color: var(--bs-body-color);
      border: var(--bs-border-width) solid var(--bs-border-color);
      border-radius: var(--bs-border-radius);
      background-image: var(--bs-form-select-bg-img),
        var(--bs-form-select-bg-icon, none);
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 16px 12px;
      appearance: none;

      text-align: start;
      &::after {
        content: none;
      }
    }
  }

  &.dropdown {
    width: fit-content;
    .dropdown-center .dropdown-toggle {
      width: 100%;
      color: var(--primary-color);
      border-radius: unset;
      border: unset;
      border-bottom: 1px solid var(--secondary-color);
      border-bottom: 1px solid var(--secondary-color);
    }
  }
}

.UserSelector {
}
