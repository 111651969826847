.Player {
  & {
    width: 100%;
    height: 100%;

  }
  .player_button {
    color: var(--primary-color);
    border-color: var(--primary-color);
    background-color: transparent;
    $player-button-size: 5rem;
    width: $player-button-size;
    height: $player-button-size;
    min-width: $player-button-size;
    svg {
      font-size: calc($player-button-size / 2);
    }

    &:hover {
      color: var(--text-on-primary-variant-color);
      border-color: var(--primary-color);
      background-color: var(--primary-color);
    }
    &:active {
      color: var(--text-on-primary-variant-color);
      border-color: var(--primary-color);
      background-color: var(--primary-variant-color);
    }
  }
}
