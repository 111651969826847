.Slide {
  &.carousel {
    .carousel-control-prev,
    .carousel-control-next {
      // color: transparent;
      color: var(--selected-on-surface-light);
      // display: none;
    }
    .carousel-control-prev {
      left: auto;
      right: 0;
      transform: scale(-1, 1);
    }
    .carousel-control-next {
      right: auto;
      left: 0;
      transform: scale(-1, 1);
    }


    .carousel-inner:has(.dropdown-menu.show) {
      overflow: visible; 
    }


  }

  
  &.hideArrows {
    &.carousel {
      .carousel-control-prev,
      .carousel-control-next {
        color: transparent;
        display: none;
      }
    }
  }
}
