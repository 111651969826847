.Table {
  &,
  & > *,
  & > * > * {
    --Table-backgroung-color: transparent;
    --Table-head-color: var(--primary-color);
    --Table-head-border-color: rgba(var(--secondary-color-rgb), 0.9);
    --Table-color: var(--text-primary-on-surface);
    --Table-active-color: var(--text-primary-on-surface);
    --Table-active-background: rgba(var(--primary-color-rgb), 0.13);
    --Table-hover-color: var(--text-primary-on-surface);
    --Table-hover-background: rgba(var(--primary-color-rgb), 0.075);
  }

  background-color: var(--Table-backgroung-color);
  color: var(--Table-color);
  margin-bottom: 1rem;

  & > * > .row {
    margin-left: 0;
    margin-right: 0;
  }
  & > .Body > .row {
    min-height: 52px;
  }

  & > .Body > .List > .Table > .Body > .row.ListItem {
    .row {
      &:hover {
        background-color: var(--Table-hover-background);
      }
      min-height: 52px;
      padding: 0.5rem;
      margin-left: 0;
      margin-right: 0;
    }
    .AccordionItem,
    .AccordionItemHeader {
      color: var(--Table-color);
    }
    .AccordionItem .AccordionItemHeader.active {
      font-weight: bold;
    }
    .AccordionItem .AccordionItemBody > * {
      margin-bottom: 20px;
      padding-left: 10px;
    }
    .AccordionItem .AccordionItemBody .label {
      font-size: 0.85rem;
      color: var(--secondary-color);
    }
  }
}
