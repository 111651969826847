.MemberDayTimeRecordTable {
  container-name: MemberDayTimeRecordTable;
  container-type: inline-size;
  // min-width: 300px;


  .WidgetCardBody {
    padding-top: 2px;
  }
}
