.UsersList.WidgetCard .RoleTabs {
    .nav-tabs-wrapper {
        display: inherit;
        flex-wrap: inherit;
        padding-left: inherit;
        margin-bottom: inherit;
        list-style: none;
    }
    .AuthRoleSelect {
        color: red;
        .dropdown-toggle  {
            color: var(--primary-color);
            border-color: var(--border-color-dark);
            font-weight: bold;

        }
    }
}
