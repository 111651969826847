.Tabs {
  .nav-tabs {
    border-bottom-color: var(--border-color-dark);
    .nav-item {
      .nav-link {
        &.btn {
          border-bottom-right-radius: unset;
          border-bottom-left-radius: unset;
          &.btn-outline-primary {
            background-color: inherit;
          }
        }
        // color: var(--primary-variant-light-color);
        color: var(--secondary-color);
        color: rgba(var(--primary-color-rgb), 0.7);
        &:hover {
          color: var(--primary-color) !important;
          border-color: transparent transparent transparent;
        }
        &.active {
          color: var(--primary-color);
          font-weight: bold;
          background: var(--surface-color);
          border-color: var(--border-color-dark);
          border-bottom: 3px solid var(--surface-color);
        }
      }
    }
  }
  .nav-pills {
    border-color: var(--border-color-dark) !important;
    .nav-item {
      .nav-link {
        &.btn {
          &.btn-outline-primary {
            background-color: inherit;
          }
        }
        border: 2px solid transparent;
        // color: var(--primary-variant-light-color);
        color: var(--secondary-color);
        color: rgba(var(--primary-color-rgb), 0.7);
        &:hover {
          color: var(--primary-color) !important;
          border-color: var(--primary-color);
        }
        &.active {
          color: var(--primary-color);
          font-weight: bold;
          background: var(--surface-color);
          border-color: var(--border-color-dark);
          border: 2px solid var(--primary-color);
        }
      }
    }
  }
  .nav-underline {
    border-color: var(--border-color-dark) !important;
  }
}
