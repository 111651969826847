@use "../../assets/styles/variables.scss" as global;

.Content {
  margin-top: 25px;
  margin-bottom: 35px;

  @media (max-width: 767px) {
    padding-left: 2px;
    padding-right: 2px;
  }
}
