.DayRecordsTable .Header {
    .data {
      color: var(--primary-color);
      flex-direction: row;
      flex-wrap: wrap;
      @container DayRecordsTable (max-width: 400px) {
        flex-direction: column;
        // .status-info {
        //   width: 100%;
        //   .DayStatusDropdown {
        //     width: 100%;
        //   }
        // }
      }
      .dataInfo {
        .react-datepicker-wrapper {
          .react-datepicker__input-container input {
            border: none;
            color: var(--primary-color);
            outline-color: var(--secondary-color);
            width: 5.2rem;
            &:read-only,
            &:disabled {
              background-color: transparent;
              outline: none;
            }
          }
        }
      }
      // .status {
      //   padding: 0.1rem 0.4rem;
      //   font-size: 0.8rem;
      //   color: var(--primary-color);
      //   .btn {
      //     padding: 0.1rem 0.4rem;
      //     font-size: 0.8rem;
      //     color: var(--primary-color);
      //     border-color: var(--secondary-color);
      //     &:hover,
      //     &:active,
      //     &:focus {
      //       color: var(--surface-color);
      //     }
      //   }
      // }
    }
    .totalHours {
      color: var(--primary-color);
      .title {
        width: max-content;
      }
    }
  .Tools {
    & > div {
      flex-wrap: wrap-reverse;
    }
    // min-width: 100px;
    .btn-outline-primary.invalid {
      --bs-btn-border-color: var(--text-primary-invalid-on-surface);
      --bs-btn-color: var(--text-primary-invalid-on-surface);
      &:hover {
        --bs-btn-hover-bg: var(--text-primary-invalid-on-surface);
        --bs-btn-hover-border-color: var(--text-primary-invalid-on-surface);
      }
    }
    @container DayRecordsTable (max-width: 500px) {
      button {
        margin-right: 0.1rem !important;
        margin-left: 0.1rem !important;
      }
    }
  }

  .openMonth {
    color: var(--secondary-color);
    .btn {
      padding: 0;
      background-color: transparent;
      border: none;
      vertical-align: unset;
      height: 100%;
      svg {
        color: var(--secondary-color);
        fill: var(--secondary-color);
        margin-right: 1px;
        position: unset;
        padding: 1px;
        vertical-align: -0.125em !important;

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
}
