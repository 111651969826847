.Clock {
  color: var(--primary-color);
  font-size: 0.7rem;
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  margin: 0px 4px;
  border-right: 1px solid var(--secondary-color);
  border-left: 1px solid var(--secondary-color);

  @media (max-width: 350px) {
    padding: 1px;
  }
  .wrapper {
    align-self: stretch;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px;
    flex-direction: column;
  }

  .clock-time {
    color: var(--primary-color-variant);
    font-weight: 400;
  }

  &.slim {
    border-right: 1px solid rgba(var(--secondary-color-rgb), 0.6);
    border-left: 1px solid rgba(var(--secondary-color-rgb), 0.6);
  
    .info {
      font-size: 0.5rem;
      line-height: 0.5;
      color: var(--secondary-color);
      &.city {
        font-size: 0.44rem;
      }
    }
    .clock-time {
      font-size: 1.1rem;
      margin-bottom: -2px;
    }
  }

  &.wide {
    .wrapper {
      padding-bottom: 2px;
    }

    .info {
      font-size: 0.6rem;
      line-height: 1.2;
      color: var(--primary-color);
      &.city {
        font-size: 0.56rem;
      }
    }
    .clock-time {
      font-size: 1.45rem;
      margin-left: 5px;
      margin-right: 4px;
    }
  }
}
