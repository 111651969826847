.FullTaskName2 {
  pointer-events: auto;
  // display: inline-block;
  white-space: normal;
  // // width: 100%;
  max-height: 100%;
  overflow-y: auto;
  word-break: break-word;
  hyphens: auto;
  cursor: pointer;
  .p2 {
    text-align: left;
  }
.fn-separator {
  margin-right: 3px;
  font-weight: 500;
}
.fn-categoryName {
  font-weight: 500;
}
.fn-taskName {
}


// .FullTaskName2 {
//   pointer-events: auto;
//   display: inline-block;
//   white-space: normal;
//   // width: 100%;
//   max-height: 100%;
//   overflow-y: auto;
//   word-break: break-word;
//   hyphens: auto;
//   cursor: pointer;
// .fn-separator {
//   margin-right: 3px;
//   font-weight: 500;
// }
// .fn-categoryName {
//   font-weight: 500;
// }
// .fn-taskName {
// }



  // .k {
  //   width: 100%;
  //   max-width: 100%;
  //   overflow: hidden;
  //   .category {
  //     max-width: 40%;
  //     min-width: 20px;
  //     color: var(--text-secondary-on-surface);
  //     .categoryName {
  //       text-overflow: ellipsis;
  //       overflow: hidden;
  //       white-space: nowrap;
  //       width: 100%;
  //     }
  //   }
  //   .t {
  //     max-width: 60%;
  //     min-width: 30px;
  //     .taskName {
  //       text-overflow: ellipsis;
  //       overflow: hidden;
  //       white-space: nowrap;
  //       width: 100%;
  //     }
  //   }
  // }
}
