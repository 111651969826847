.CrossBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    padding: 0.25em;
    color: rgba(var(--text-secondary-on-surface-rgb), 0.5);
    // opacity: 0.2;
    // background: transparent var(--btn-close-bg) center/1em auto no-repeat;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out, box-shadow .15s ease-in-out, opacity .15s;
    cursor: pointer;
    &:hover {
        color: rgba(var(--text-secondary-on-surface-rgb), 0.9);
    }
    svg {
        height: inherit;
    }
}
