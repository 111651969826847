.ResetPasswordForm {
  .link_login a {
        color: var(--primary-color);
        color: var(--secondary-color);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          color: var(--primary-color);

        }
      }
}