@use "../../../../assets/styles/variables.scss" as global;

.Card .Modal {
    &.hidden {
        display: none !important;
    }
  $modal-z-index: global.$z-index-third;
  --zindex-modal: $modal-z-index;
  // z-index: $modal-z-index !important;
  padding: 10% 5%;
  background-color: var(--backdrop-color);
  width: 100%;
  height: 100%;
  display: block;

  .close {
    z-index: calc(var(--zindex-modal) + 1);
    margin: 3px 7px;
    position: relative;

    div, .CrossBtn {
      float: right;
      // box-sizing: content-box;
      // $close-size: 0.2em;
      // width: $close-size;
      // height: $close-size;
      // padding: 0.25em;
      // color: var(--text-secondary-on-surface);
      // opacity: 0.2;
      // background: transparent var(--btn-close-bg) center/1em auto no-repeat;
      // cursor: pointer;
    }
    // span {
    //     margin: 4px;z
    // }
  }

  @media (max-width: 767px) {
    padding-left: 2%;
    padding-right: 2%;

    & > .SimpleCard {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
