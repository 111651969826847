.Members {
    .Accordion .AccordionItem.card .card-body {
        padding-left: 0 !important;

        .Requests {
            border-left: none;
            .Request {
                padding-left: calc(var(--bs-gutter-x) * 0.5 + 0.5rem + 11px);
            }
        }
    }
}