@use "./theme_variables.scss" as theme;
@use "./variables.scss" as vars;

@function rgb($color) {
  @debug $color;
  @return "" + red($color) + ", " + green($color) + ", " + blue($color) + "";
}

:root,
[data-bs-theme="light"] {
  --bs-primary: #{map-get(theme.$light-theme, "primary-color")};
  --bs-primary-rgb: #{rgb(map-get(theme.$light-theme, "primary-color"))};

  --bs-primary-variant-color: #{map-get(theme.$light-theme, "primary-variant-color")};
  --bs-primary-variant-color-rgb: #{rgb(map-get(theme.$light-theme, "primary-variant-color"))};

  --bs-secondary: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-secondary-rgb: #{rgb(map-get(theme.$light-theme, "secondary-color"))};

  --bs-invalid-primary: #{map-get(
      theme.$light-theme,
      "text-primary-invalid-on-surface"
    )};
  --bs-invalid-primary-rgb: #{rgb(
      map-get(theme.$light-theme, "text-primary-invalid-on-surface")
    )};
  --bs-invalid-primary-variant: #{map-get(
      theme.$light-theme,
      "primary-invalid-variant-color"
    )};
  --bs-invalid-primary-variant-rgb: #{rgb(
      map-get(theme.$light-theme, "primary-invalid-variant-color")
    )};

  --bs-invalid-secondary: #{map-get(
      theme.$light-theme,
      "text-secondary-invalid-on-surface"
    )};
  --bs-invalid-secondary-rgb: #{rgb(
      map-get(theme.$light-theme, "text-secondary-invalid-on-surface")
    )};

  --bs-body-color: #{map-get(theme.$light-theme, "text-primary-on-background")};
  --bs-body-color-rgb: #{rgb(
      map-get(theme.$light-theme, "text-primary-on-background")
    )};
  --bs-body-bg: #{map-get(theme.$light-theme, "background-color")};
  --bs-body-bg-rgb: #{rgb(map-get(theme.$light-theme, "background-color"))};

  --bs-surface-color: #{map-get(theme.$light-theme, "text-primary-on-surface")};
  --bs-surface-color-rgb: #{rgb(
      map-get(theme.$light-theme, "text-primary-on-surface")
    )};
  --bs-surface-bg: #{map-get(theme.$light-theme, "surface-color")};
  --bs-surface-bg-rgb: #{rgb(map-get(theme.$light-theme, "surface-color"))};

  --bs-emphasis-color: #{map-get(theme.$light-theme, "emphasis-color")};
  --bs-emphasis-color-rgb: #{rgb(map-get(theme.$light-theme, "emphasis-color"))};
  --bs-text-on-empasis-color: #{map-get(
      theme.$light-theme,
      "text-on-emphasis-color"
    )};
  //   --bs-text-on-empasis-color-rgb: #{rgb(map-get(theme.$light-theme, "text-on-emphasis-color"))};
  //   --bs-text-on-empasis-color-rgb: #{rgb($colors)};
  --bs-border-width: 2px;

  --bs-form-select-bg-img: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');
  
}
.btn {
  // --bs-btn-padding-y: 0.375rem;
  // --bs-btn-font-family: ;
  // --bs-btn-font-size: 1rem;
  // --bs-btn-font-weight: 400;
  // --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  --bs-btn-hover-bg: transparent;

  &:focus-visible {
    box-shadow: none !important;
  }


  
}
.btn-primary {
  --bs-btn-color: #{map-get(theme.$light-theme, "text-on-secondary-color")};
  --bs-btn-bg: #{map-get(theme.$light-theme, "primary-color")};
  --bs-btn-border-color: #{map-get(theme.$light-theme, "primary-color")};
  --bs-btn-hover-color: #{map-get(
      theme.$light-theme,
      "text-on-primary-variant-color"
    )};
  --bs-btn-hover-bg: #{map-get(theme.$light-theme, "primary-variant-color")};
  --bs-btn-hover-border-color: #{map-get(
      theme.$light-theme,
      "primary-variant-color"
    )};
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #{map-get(
      theme.$light-theme,
      "text-on-primary-variant-color"
    )};
  --bs-btn-active-bg: #{map-get(theme.$light-theme, "primary-variant-color")};
  --bs-btn-active-border-color: #{map-get(
      theme.$light-theme,
      "primary-variant-color"
    )};
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #{map-get(
      theme.$light-theme,
      "text-on-primary-color"
    )};
  --bs-btn-disabled-bg: #{map-get(theme.$light-theme, "primary-color")};
  --bs-btn-disabled-border-color: #{map-get(theme.$light-theme, "primary-color")};
}
.btn-secondary {
  --bs-btn-color: #{map-get(theme.$light-theme, "text-on-secondary-color")};
  --bs-btn-bg: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-border-color: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-hover-color: #{map-get(theme.$light-theme, "text-on-secondary-color")};
  --bs-btn-hover-bg: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-hover-border-color: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #{map-get(
      theme.$light-theme,
      "text-on-secondary-color"
    )};
  --bs-btn-active-bg: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-active-border-color: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #{map-get(
      theme.$light-theme,
      "text-on-secondary-color"
    )};
  --bs-btn-disabled-bg: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-disabled-border-color: #{map-get(
      theme.$light-theme,
      "secondary-color"
    )};
}

.btn-outline-primary {
  --bs-btn-color: #{map-get(theme.$light-theme, "primary-color")};
  --bs-btn-border-color: #{map-get(theme.$light-theme, "primary-color")};
  --bs-btn-hover-color: #{map-get(theme.$light-theme, "text-on-primary-color")};
  --bs-btn-hover-bg: #{map-get(theme.$light-theme, "primary-color")};
  --bs-btn-hover-border-color: #{map-get(theme.$light-theme, "primary-color")};
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #{map-get(theme.$light-theme, "text-on-primary-color")};
  --bs-btn-active-bg: #{map-get(theme.$light-theme, "primary-color")};
  --bs-btn-active-border-color: #{map-get(theme.$light-theme, "primary-color")};
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #{map-get(theme.$light-theme, "primary-color")};
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #{map-get(theme.$light-theme, "primary-color")};
  --bs-gradient: none;
}
.btn-outline-secondary {
  --bs-btn-color: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-border-color: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-hover-color: #{map-get(theme.$light-theme, "text-on-secondary-color")};
  --bs-btn-hover-bg: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-hover-border-color: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #{map-get(
      theme.$light-theme,
      "text-on-secondary-color"
    )};
  --bs-btn-active-bg: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-active-border-color: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #{map-get(
      theme.$light-theme,
      "secondary-color"
    )};
  --bs-gradient: none;
}
.btn-light {
  --bs-btn-color: #{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #{map-get(theme.$light-theme, "primary-color")};
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #{map-get(theme.$light-theme, "primary-variant-color")};
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgba(var(--bs-secondary-rgb), 0.3); //#{map-get(theme.$light-theme, "secondary-color")};
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: transparent;
  --bs-gradient: none;

  // @media all and (max-width: 576px) {
  //   --bs-btn-color: var(--bs-btn-hover-color);
  // }

}
.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(
    var(--bs-border-radius) - (var(--bs-border-width))
  );
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: var(--bs-surface-bg);
  --bs-card-cap-color: var(--bs-surface-color);
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-surface-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
}
.card-header {
  border-bottom: none;
}
.card-footer {
  border-top: none;
}

.tooltip {
  // --bs-tooltip-zindex: 1080;
  // --bs-tooltip-max-width: 200px;
  // --bs-tooltip-padding-x: 0.5rem;
  // --bs-tooltip-padding-y: 0.25rem;
  // --bs-tooltip-margin: ;
  // --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-text-on-empasis-color);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  // --bs-tooltip-border-radius: var(--bs-border-radius);
  // --bs-tooltip-opacity: 0.9;
  // --bs-tooltip-arrow-width: 0.8rem;
  // --bs-tooltip-arrow-height: 0.4rem;
}

.table {
  // --bs-table-color-type: initial;
  // --bs-table-bg-type: initial;
  // --bs-table-color-state: initial;
  // --bs-table-bg-state: initial;
  --bs-table-color: var(--text-primary-on-surface);
  --bs-table-bg: var(--bs-surface-bg);
  --bs-table-border-color: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--text-primary-on-surface);
  --bs-table-striped-bg: rgba(var(--bs-primary-rgb), 0.05);
  --bs-table-active-color: var(--text-primary-on-surface);
  --bs-table-active-bg: rgba(var(--bs-primary-rgb), 0.1);
  --bs-table-hover-color: var(--text-primary-on-surface);
  --bs-table-hover-bg: rgba(var(--bs-primary-rgb), 0.075);
  // --bs-table-hover-bg: rgba(var(--bs-primary-rgb), 0.075);
}
.table > thead > * {
  border-bottom: 1px solid var(--bs-secondary);
}
.table > thead > * > * {
  --bs-table-color: var(--bs-primary);
}

.form-control,
.form-select {
  background-color: var(--bs-surface-bg);
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-color: transparent;
  border-color: var(--bs-secondary);
  outline: 0;
  box-shadow: none;
}
.input-group-text {
  background-color: var(--bs-body-bg);
}

.dropdown, .dropdown-center {
  .dropdown-menu {
    border: 1px solid var(--border-color);
    background-color: var(--surface-color);
    box-shadow: 0px 3px 3px 0px rgba(var(--secondary-color-rgb), 0.3);
    .dropdown-header {
      color: var(--text-secondary-on-surface);
      cursor: default;
    }
    .dropdown-item {
      color: var(--text-primary-on-surface);
    
      // &:focus {
      //   background-color: var(--surface-color);
      // }

      &:hover {
        background-color: var(--hover-on-surface-light);
      }
      &:active {
        background-color: var(--hover-on-surface-dark);
      }
      &:focus, &:focus-visible {
        outline: none;
        background-color: var(--hover-on-surface-light);
      }
    }
  }
}

.row > *:not(.col) {
  padding-left: 0;
  padding-right: 0;
}