.DayRecordsTable .Modal .ModalEditClockedTimeRecord {
    &.Unverified {
        .btn-outline-primary.delete {
            --bs-btn-color: var(--bs-invalid-primary);
            --bs-btn-border-color: var(--bs-invalid-primary);
            --bs-btn-hover-bg: var(--bs-invalid-primary-variant);
            --bs-btn-hover-border-color: var(--bs-invalid-primary-variant);
            --bs-btn-active-bg: var(--bs-invalid-primary-variant);
            --bs-btn-active-border-color: var(--bs-invalid-primary-variant);
            --bs-btn-disabled-color: var(--bs-invalid-primary);
            --bs-btn-disabled-border-color: var(--bs-invalid-primary);
        }
        .btn-primary.submit {
            --bs-btn-bg: var(--bs-invalid-primary);
            --bs-btn-border-color: var(--bs-invalid-primary);
            --bs-btn-hover-bg: var(--bs-invalid-primary-variant);
            --bs-btn-hover-border-color: var(--bs-invalid-primary-variant);
            --bs-btn-focus-shadow-rgb: 49, 132, 253;
            --bs-btn-active-bg: var(--bs-invalid-primary-variant);
            --bs-btn-active-border-color: var(--bs-invalid-primary-variant);
            --bs-btn-disabled-bg: var(--bs-invalid-primary);
            --bs-btn-disabled-border-color: var(--bs-invalid-primary);
    
        }
    }

}