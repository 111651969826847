.MonthRecordsTable {
    container-name: MonthRecordsTable;
    container-type: inline-size;
    // min-width: 300px;

    .Modal {
        position: relative;
        .card {
            position: sticky;
            top: 5%;
        }
    }
    .Modal .DayModal .TopExtras {
        // top: -15px;
        top: -27px;
    }

    .WidgetCardBody {
        padding-top: calc(var(--bs-card-spacer-y)  / 2);
      }
}


.MonthTimeRecordProvider {

    &.loading {
        pointer-events: none;
        // opacity: 0.5;
        opacity: 0.6;
        animation: placeholder-glow 1.5s ease-in-out infinite;
    }


}