.Lost {
  --lost-background-color: var(--background-color);
  --lost-blackhole-color: var(--primary-variant-darker-color);
  --lost-header-text-color: var(--primary-color);
  --lost-text-color: var(--primary-variant-light-color);
  --lost-text-hover-color: var(--lost-header-text-color);
  --lost-border-color: var(--border-color);
  --lost-border-hover-color: var(--secondary-color);

  $screen-size-small: 576px;

  & {
    display: flex;
    align-items: center;
    background: var(--lost-background-color);
    min-width: 275px;
    height: 100vh;
    padding: 0 10vw;
    overflow: hidden;
    color: var(--lost-text-color);
    font-family: Roboto;
  }

  .wrapper {
    flex-grow: 2;
    width: 40vw;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 3%;
    @media screen and (max-width: $screen-size-small) {
        text-align: center;
        padding: 0 20%;
    }

    h1 {
      margin: 0;
      font-size: 6em;
      font-weight: 100;
      color: var(--lost-header-text-color);
      @media screen and (max-width: $screen-size-small) {
        font-size: 4em;
      }
    }

    p {
      width: 95%;
      font-size: 1.5em;
      line-height: 1.4;

      .extra {
        @media screen and (max-width: $screen-size-small) {
            display: none;
        }
      }
    }

    .buttons {
      white-space: nowrap;
      display: inline-block;

      span {
        display: block;
        text-transform: uppercase;
        color: var(--lost-text-color);
        letter-spacing: 1.5px;
        text-align: center;
      }

      a {
        display: inline-block;
        padding: 0.8em 1em;
        margin-right: 1em;
        margin-bottom: 1em;
        border: 3px solid var(--lost-border-color);
        color: var(--lost-text-color);
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 0.2em;
        position: relative;
        overflow: hidden;
        transition: 0.3s;

        &:hover {
            border-color: var(--lost-border-hover-color);
            color: var(--lost-text-hover-color);
        }
        &:hover::before {
          top: 0;
        }
        &:before {
          content: "";
          background: var(--lost-text-color);
          height: 100%;
          width: 100%;
          position: absolute;
          top: -100%;
          left: 0;
          transition: 0.3s;
          z-index: -1;
        }
      }
    }
  }

  .space {
    width: 75px;
    height: calc(50vh + 37.5px);
    border-top-left-radius: 37.5px;
    border-top-right-radius: 37.5px;
    overflow: hidden;
    margin: calc(50vh - 37.5px) auto 0 auto;
    position: relative;
    pointer-events: none;
    -webkit-transform: translateZ(0);

    @media screen and (max-width: $screen-size-small) {
        display: none;
    }

    .blackhole {
      border: 5px solid var(--lost-blackhole-color);
      height: 75px;
      width: 75px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;

      &:after {
        content: "";
        height: calc(100% + 10px);
        width: calc(100% + 10px);
        border: 5px solid var(--lost-blackhole-color);
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-radius: 50%;
        position: absolute;
        top: -5px;
        left: -5px;
        z-index: 5;
        transform: rotate(45deg);
      }
    }
    .ship {
      height: 150px;
      width: 55px;
      margin-left: 10px;
      background: url("../../assets/images/spaceShip2.png")
        center/contain no-repeat;
      animation: blackhole 4s infinite linear;
      position: absolute;
      bottom: -150px;

      @keyframes blackhole {
        to {
          transform: translateY(-100vh);
        }
      }
    }
  }
}
