.Dashboard {
  & > div > .col {
    margin: 10px;
    z-index: 0;
    &:focus,
    &:focus-within {
      z-index: 1;
    }
    @media (max-width: 767px) {
    margin: 10px 0px 10px 0px;
    }
  }
}
