.MultiSelect {
  & *:focus-visible {
    outline: none;
  }
.rbt-input-multi.focus {
  box-shadow: none;
  border-color: var(--bs-secondary);;
}
  .rbt-token {
    background-color: rgba(var(--secondary-color-rgb), 0.26);
    color: var(--primary-variant-color);
    align-items: center;
  }
  .rbt-menu {
    background-color: var(--surface-color);
    &:focus-visible {
      outline: none;
    }
    & > .dropdown-item {
      &:active {
        background-color: var(--hover-on-surface-dark);
        color: var(--text-primary-on-surface);
      }
      &:focus,
      &:hover,
      &:focus-visible {
        background-color: var(--hover-on-surface-dark);
      }
      &:focus-visible {
        outline: none;
      }
    }
  }

  &.no-input {
    caret-color: transparent;
    & > .rbt-input-multi {
      cursor: unset;
      & input {
        cursor: default !important;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}
