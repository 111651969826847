.AuthRoleSelect {
  width: 100%;

  .dropdown,
  .dropdown-center {
    // max-width: 70%;
    .dropdown-toggle {
      background-color: transparent;

      // width: 100%;
      // text-overflow: ellipsis;
      // overflow: hidden;
      padding-right: 30px;
    }
    .dropdown-menu {
      border: 1px solid var(--border-color);
      max-height: 300px;
    //   overflow-y: scroll;
      overflow-y: auto;
      .dropdown-header {
        color: var(--text-secondary-on-surface);
        cursor: default;
        & .public {
          color: var(--text-disabled-on-surface);
        }
      }
      .dropdown-item {
        color: var(--text-primary-on-surface);
        &:hover {
          background-color: var(--hover-on-surface-light);
        }
        &:active, &.active {
          background-color: var(--hover-on-surface-dark);
        }
      }
    }
  }

  &.AuthRoleSelect-select .dropdown-toggle {
    width: 100%;
    // form-select
    color: var(--bs-body-color);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    background-image: var(--bs-form-select-bg-img),
      var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    appearance: none;

    text-align: start;
    &::after {
      content: none;
    }
  }
  &.AuthRoleSelect-dropdown .dropdown-toggle {
    color: var(--primary-color);
    border-radius: unset;
    border: unset;
    border-bottom: 1px solid var(--secondary-color);
    border-bottom: 1px solid var(--secondary-color);
  }
  .AuthRoleSelectPlaceholder {
    &.placeholder,
    &.placeholder-wave,
    &.placeholder-glow {
      background-color: transparent;
      .btn {
        background-color: transparent;
        color: var(--primary-color);
        border-radius: unset;
        border: unset;
        border-bottom: 1px solid var(--secondary-color);
        border-bottom: 1px solid var(--secondary-color);
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 70%;
      }
    }
  }
}
