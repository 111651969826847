.Table .Body {
  & > .row {
    padding: 0.5rem;
  }

  & > .row.active {
    color: var(--Table-active-color);
    background-color: var(--Table-active-background);
  }

  @media (hover: hover) and (pointer: fine) {
    & > .row:hover {
      color: var(--Table-hover-color);
      background-color: var(--Table-hover-background);
    }
  }
  .row {
    & .table-row-btn {
      // color: rgba(var(--secondary-color-rgb), 0.7);
      border: none;
      background-color: transparent;
      &:active,
      &:focus,
      &:hover {
        background-color: transparent;
        outline: none;
        border: none;
      }
      // &:disabled {
      //     // color: rgba(var(--bs-secondary-rgb), 0.3);
      //   }
      @media all and (max-width: 576px) {
        // color: rgba(var(--primary-color-rgb), 0.8);
        padding-left: calc(var(--bs-btn-padding-x) * 0.5) ;
        padding-right: calc(var(--bs-btn-padding-x) * 0.5) ;
      }
    }
    @media (hover: hover) and (pointer: fine) {
    &:hover,
    &.active {
      .table-row-btn {
        color: var(--primary-color);
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: transparent;
            color: var(--primary-variant-color);
            transition: linear 0.3s;
            & svg {
              scale: 1.1;
            }
          }
        }
        &:disabled {
          color: rgba(var(--bs-secondary-rgb), 0.3);
          &:hover {
            & svg {
              scale: none;
            }
          }
        }
      }
    }
  }
  }
}
