.TimeMinutesInHours {
  .parens {
    display: none;
    opacity: 0.5;
  }
  &.in-parens {
    .parens {
      display: inline;
    }
  }
  .tit {
    font-size: 0.75rem;
  }
  .number {
    // font-size: 1.05rem;
    font-size: 1rem;
  }
  &.larger {
    .tit {
      font-size: 0.85rem;
    }
    .number {
      font-size: 1.05rem;
    }
  }
}
