.UsersList.WidgetCard .RoleUsersList .User {
  .fa-caret-left {
    transform: rotate(180deg);
    color: var(--secondary-color);
    cursor: pointer;
  }
  &.active {
    font-weight: bold;
    .fa-caret-left {
      transform: rotate(270deg);
    }
  }

  .name {
    // max-width: 80%; 
    // min-width: fit-content;
  }
  
}
