.ButtonScale {
  &.btn {
    color: rgba(var(--bs-secondary-rgb), 0.7);
    border: none;
    background-color: transparent;
    &:active,
    &:focus,
    &:hover {
      background-color: transparent;
      outline: none;
      border: none;
    }
    &:disabled {
      color: transparent;
    }
    &:hover {
        color: var(--bs-primary-variant-color);
        transition: linear 0.3s;
        & svg {
          scale: 1.1;
        }
    }
  }
  &.parent-hovered {
    &.btn:not([disabled]) {
      color: var(--bs-primary);
      &:hover {
    //     // background-color: transparent;
        color: var(--bs-primary-variant-color);
        // transition: linear 1.3s;
    //     // & svg {
    //     //   scale: 1.1;
    //     // }
      }
    }
  }
}
