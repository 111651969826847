.ResetPassword {
  background-color: var(--background-variant-color);
  & {
    height: 100%;
  }
  .Container {
    height: 100%;
    min-width: 200px;
  }
  $form-margin-top: 20%;

  .form-container {
    //     height: 100% - $form-margin-top;
    max-width: 600px;
    margin: auto;
    padding-top: 7%;
    .from-label {
      color: var(--text-secondary-on-surface);
    }
  }
  .link_login a {
    color: var(--primary-color);
    color: var(--secondary-color);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: var(--primary-color);
    }
  }
}
