.Login {
  background-color: var(--background-variant-color);
  & {
    height: 100%;
  }
  .Container {
    height: 100%;
    min-width: 200px;

    $form-margin-top: 20%;
    .logo-continer {
      height: $form-margin-top;
      .logo {
        margin: auto;
        max-width: 450px;
      }
    }

    .form-container {
      height: 100% - $form-margin-top;
      max-width: 600px;
      margin: auto;
      .card {
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
        .card-header {
          border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
        }
        .card-body {
          .form-label {
            color: var(--text-secondary-on-surface);
          }
          .forgot-password {
            a {
              color: var(--primary-color);
              color: var(--secondary-color);
              text-decoration: none;
              &:hover {
                text-decoration: underline;
                color: var(--primary-color);

              }
            }
          }
        }
      }
    }
  }
}
