@use "../../assets/styles/variables.scss" as global;

$navbar-z-index: global.$z-index-first;
$navbar-height: global.$navbar-height;

.Navbar {
  z-index: $navbar-z-index !important;
  background-color: var(--surface-color);
  box-shadow: var(--box-shadow);
  height: $navbar-height;
  // min-width: 312px;
  // TO DO
  // overflow: hidden;
  .nav1 {
    // height: 100%;
    height: 90%;
    .brand {
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
      img {
        width: auto;
        height: 100%;
      }
      &:has(> img.small) {
        margin-right: 8px;
      }
    }
    .pageTitle {
      border-left: 1px solid var(--secondary-color);
      padding-left: 12px;
      padding-right: 5px;
      display: flex;
      align-items: center;
      font-size: 1.05rem;
      color: var(--primary-color);
      &.short {
        border: none;
        padding-left: 4px;
        font-size: 0.9rem;
      }
    }
  }
  .nav2 {
    // height: 100%;
    height: 90%;
    .dropdown {
      // background-color: var(--surface-color);
      background-color: transparent;
      color: var(--text-primary-on-surface);

      a.dropdown-toggle {
        color: var(--primary-color);
        background: transparent;
        @media (max-width: 360px) {
          padding-left: 4px;
          padding-right: 4px;
        }
      }

      .dropdown-header {
        color: var(--text-secondary-on-surface);
      }
      .dropdown-item {
        color: var(--text-primary-on-surface);
        &:hover {
          background-color: var(--hover-on-surface-light);
        }
        &:active {
          background-color: var(--hover-on-surface-dark);
        }
      }
    }
    @media (max-width: 320px) {
      & .clock_wrapper {
        display: none;
      }
    }
  }
}
