$z-index-overlay-top: 1060;
$z-index_top: 1050;

// Nav Side
$z-index-first: 1046;
$z-index-second: 1045;

// Modals
$z-index-third: 1040;

// Cards
$z-index-forth: 1035;

// TabBar
$z-index-fifth: 1043;

// Cards grids
// $z-index-forth: 1035;


// Navbar Height
$navbar-height: 60px






