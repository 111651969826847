.AdminTasksList .Header {
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
    padding: 0.5rem;
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between;
    align-items: center;

    --Table-head-color: var(--primary-color);
    --Table-head-border-color: rgba(var(--secondary-color-rgb), 0.9);
    color: var(--Table-head-color);
    border-bottom: 2px solid var(--Table-head-border-color);
    font-weight: bold;
}