$xs-breakpoint: 376px;
$sm-breakpoint: 576px;
$md-breakpoint: 768px;
$lg-breakpoint: 992px;
$xl-breakpoint: 1200px;
$xxl-breakpoint: 1400px;
* {
  --sm-breakpoint: #{$sm-breakpoint};
}

// Regular
//> All

//> XS
// @media all and (max-width: $sm-breakpoint) {
//   .tt {
//     color: violet;
//   }
// }
// Containers
//> All




//> XS
@container (min-width: #{$xs-breakpoint}) {
    .d-c-xs-block {
        display: block !important;
    }
    .d-c-xs-inline {
      display: inline !important;
  }
}

//> SM

@container (min-width: #{$sm-breakpoint}) {
.d-c-sm-none {
    display: none !important;
}
.d-c-sm-block {
    display: block !important;
}
.d-c-sm-inline {
  display: inline !important;
}
  .justify-content-c-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-c-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-c-sm-between {
    justify-content: space-between!important;
  }

}

// MD
@container (min-width: #{$md-breakpoint}) {
  .d-c-md-none {
    display: none !important;
}
    .d-c-md-block {
        display: block !important;
    }
    .d-c-md-inline {
      display: inline !important;
  }
}

// LG
@container (min-width: #{$lg-breakpoint}) {
  .d-c-lg-none {
    display: none !important;
}
    .d-c-lg-block {
        display: block !important;
    }
    .d-c-lg-inline {
      display: inline !important;
  }
}
