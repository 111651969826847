.Modal {
  .SimpleCard {
    min-width: 250px;
  }
  .Card {
    min-width: 250px;
  }


  .DayRecordsTable > .SimpleCard, .DayRecordsTable > .SimpleCard > .Grid > .Node > .WidgetCardHeader, .DayRecordsTable > .SimpleCard > .Grid > .Node > .WidgetCardHeader > .Header {
    padding-top: 0;
  }
}
