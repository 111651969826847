@use "../../assets/styles/variables.scss" as global;
$alert-z-index: global.$z-index_top;

.AlertOffcanvas {
  .Alert {
    &.alert-danger {
    --bs-alert-color: var(--text-primary-invalid-on-surface);
    // --bs-alert-bg:  #e41749;
    // --bs-alert-bg: #de3f64;
    // --bs-alert-bg: #e24b5e;
    // --bs-alert-bg: #e24b5e;
    --bs-alert-border-color: var(--text-secondary-invalid-on-surface);
    --bs-alert-link-color: var(--bs-danger-text-emphasis);
    }
    position: absolute;
    font-weight: 500;
    width: 100%;
    top: 0;
    left: 0;
    z-index: $alert-z-index;
    
    .btn-close {
      outline: none;
      box-shadow: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23910000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
    }

    &.alert-success {
      .btn-close {
        color: blue;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%33910000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
      }
    //   --bs-alert-color: var(--text-primary-invalid-on-surface);
    // // --bs-alert-bg:  #e41749;
    // // --bs-alert-bg: #de3f64;
    // // --bs-alert-bg: #e24b5e;
    // // --bs-alert-bg: #e24b5e;
    // --bs-alert-border-color: var(--text-secondary-invalid-on-surface);
    // --bs-alert-link-color: var(--bs-danger-text-emphasis);

    }
  }
}
