@use "../../assets/styles/variables.scss" as global;

$sidebar-padding-top: global.$navbar-height;
$sidebar-z-index: global.$z-index-second;

.offcanvas-backdrop {
  opacity: 1;
  background-color: var(--backdrop-color);
}

.Sidebar {
  z-index: $sidebar-z-index !important;
  border-right: none !important;
  box-shadow: var(--box-shadow) !important;
  width: 300px !important;
  // padding-top: $sidebar-padding-top;

  .category {
    color: var(--text-secondary-on-surface);
  }
  .no-st-lk {
    text-decoration: none;
    .link {
      color: var(--text-primary-on-surface);
      cursor: pointer;

      $link-top-bottom-padding: 0.75rem;
      padding-top: $link-top-bottom-padding;
      padding-bottom: $link-top-bottom-padding;
      &:hover {
        background-color: var(--hover-on-surface-light);
      }
      &:active,
      &.active {
        background-color: var(--hover-on-surface-dark);
      }
    }
    &.active .link {
      background-color: var(--hover-on-surface-dark);
    }
    // &.active {
    //   .link {
    //     background-color: var(--hover-on-surface-dark);
    //   }
    // }
  }
  // .content {
    .no-st-lk {
      .link {
        $link-side-margin: -1rem;
        margin-right: $link-side-margin;
        margin-left: $link-side-margin;
      }
    }
  // }
  .footer {
    cursor: default;
    color: var(--text-primary-on-surface);
    .lighter {
      color: var(--text-secondary-on-surface);
    }
    .no-st-lk {
      .link {
        // margin: 0;
      }
    }
  }
  .offcanvas-header .btn-close {
    width: 0.4em;
    height: 0.4em;
    padding: 0.25em;
    // margin-top: -2em;
    color: var(--text-secondary-on-surface);
    opacity: 0.2;
  }
}
