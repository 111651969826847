.CardFooter {
    opacity: 0.7;
    .small {
        font-size: .75em;
    }
    .count {
        color: var(--primary-color);
        .invalid {
            color: var(--text-primary-invalid-on-surface);
            &.disabled {
                color: var(--text-disabled-on-surface);
            }
        }
        .divider {
            color: var(--text-secondary-on-surface);
            opacity: 0.5;
        }
    }
}