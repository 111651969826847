.PendingMembersRequestsList .RequestsList .Month {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  $sm-breakpoint: 576px;
  .DatePicker {
    margin-right: 0 !important;
  }
  .fa-caret-left {
    transform: rotate(180deg);
    color: var(--secondary-color);
    cursor: pointer;
  }
  .count {
    display: block;
    @media (max-width: ($sm-breakpoint - 1)) {
      display: none !important;
    }
    cursor: default;
  }
  .open {
    display: none;
    @media (max-width: ($sm-breakpoint - 1)) {
      display: none !important;
    }
    color: var(--secondary-color);
  }
  &.active {
    font-weight: bold;
    .fa-caret-left {
      transform: rotate(270deg);
      // color: var(--primary-color);
    }
    .count {
      display: none;
    }
    .open {
      display: block;
    }
    .date {
      .DatePicker-container .react-datepicker__input-container {
        text-decoration: underline;
      }
      color: var(--secondary-color);
    }
  }

  .actions .TooltipButton {
    padding-right: 0.55rem;
    padding-left: 0.55rem;
    @media (max-width: $sm-breakpoint) {
      padding-right: 0.25rem;
      padding-left: 0.25rem;
    }
  }

  &.warning {
    &:hover {
      background-color: rgba( var(--text-primary-invalid-on-surface-rgb), 0.055 );
    }
    & > div > .open {
      color: rgba(var(--text-primary-invalid-on-surface-rgb), 0.6 );
    }
    & > div > .count > .badge {
      background-color: rgba(var(--text-primary-invalid-on-surface-rgb), 0.55 ) !important;
    }
    &:hover {
      & > div > .count > .badge {
      background-color: rgba(var(--text-primary-invalid-on-surface-rgb), 0.8 ) !important;
      }
    }
  }
}
