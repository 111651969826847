.SnapError {
    --lost-background-color: var(--background-color);
    --lost-blackhole-color: var(--primary-variant-darker-color);
    --lost-header-text-color: var(--primary-color);
    --lost-text-color: var(--primary-variant-light-color);
    --lost-text-hover-color: var(--lost-header-text-color);
    --lost-border-color: var(--border-color);
    --lost-border-hover-color: var(--secondary-color);

    $screen-size-small: 576px;

    & {
        display: flex;
        align-items: center;
        background: var(--lost-background-color);
        min-width: 275px;
        height: 100vh;
        padding: 0 10vw;
        overflow: hidden;
        color: var(--lost-text-color);
        font-family: Roboto;
      }
      .wrapper {
        flex-grow: 2;
        width: 40vw;
        max-width: 500px;
        margin: 0 auto;
        padding: 0 3%;
        text-align: center;
        margin-top: -90px;
        // @media screen and (max-width: $screen-size-small) {
        //     text-align: center;
        //     padding: 0 10%;
        // }
        
        .locked {
        // width: 100%;
        // max-height: 20px;
        height: 70px;
        margin-bottom: 20px;
        img {
            width: auto;
            height: 100%;
        }
        }

        h1 {
          margin: 0;
          font-size: 6em;
          font-weight: 100;
          color: var(--lost-header-text-color);
          @media screen and (max-width: $screen-size-small) {
            font-size: 3em;
          }
        }
    
        p {
          width: 95%;
          font-size: 1.5em;
          line-height: 1.4;
    
          .extra {
            @media screen and (max-width: $screen-size-small) {
                display: none;
            }
          }
        }
    
        .buttons {
          white-space: nowrap;
          display: inline-block;
    
          span {
            display: block;
            text-transform: uppercase;
            color: var(--lost-text-color);
            letter-spacing: 1.5px;
            text-align: center;
          }
    
          a, button {
            display: inline-block;
            padding: 0.8em 1em;
            margin-right: 1em;
            margin-bottom: 1em;
            border: 3px solid var(--lost-border-color);
            color: var(--lost-text-color);
            font-weight: 600;
            text-transform: uppercase;
            text-decoration: none;
            letter-spacing: 0.2em;
            position: relative;
            overflow: hidden;
            transition: 0.3s;
            background-color: transparent;
    
            &:hover {
                border-color: var(--lost-border-hover-color);
                color: var(--lost-text-hover-color);
            }
            &:hover::before {
              top: 0;
            }
            &:before {
              content: "";
              background: var(--lost-text-color);
              height: 100%;
              width: 100%;
              position: absolute;
              top: -100%;
              left: 0;
              transition: 0.3s;
              z-index: -1;
            }
          }
        }
      }

      .side {
        width: 10vw;
        height: 100%;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $screen-size-small) {
            display: none;
        }

        .lock {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-bottom: 80px;
            img {
                width: 80%;
                max-width: 150px;
                margin: auto;
            }
        }
      }
    
}