.RecordsTable {
    $screen-size-small: 435px;
    // @container (max-width: #{$screen-size-small}) {
    //   font-size: 0.9rem;
    //   & .Table .Head {
    //     font-size: 0.795rem;
    //   }
    // }
    & .Table .Body .taskName {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    & .Table .Body {
      margin-bottom: 30px;
    }
}