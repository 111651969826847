.MemberDayTimeRecordTable .Header {
  .UserSelector {
    width: 100%;
    margin-bottom: 15px;
  }
  .openMonth {
    color: var(--secondary-color);
    .btn {
      padding: 0;
      background-color: transparent;
      border: none;
      vertical-align: unset;
      height: 100%;
      svg {
        color: var(--secondary-color);
        fill: var(--secondary-color);
        margin-right: 1px;
        position: unset;
        padding: 1px;
        vertical-align: -0.125em !important;

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
  .totalHours {
    color: var(--primary-color);
  }
}
