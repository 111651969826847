.UsersList.WidgetCard .RoleUsersList {
  .UserInfo {
    .label {
      color: var(--secondary-color);
      // width: 80px;
    }
    .value {
        overflow-wrap: break-word;
        overflow-x: auto;
    }
  }


  .User {
    &.disabled {
      // opacity: 0.3;
      opacity: 0.6;
      pointer-events: none;
      animation: placeholder-glow 1.5s ease-in-out infinite;
    }
  }
}
