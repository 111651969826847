.MembersMonthlyTimesheetsTable .SummaryTable {

  // .row {
  //   & .openMonth, & .download {
  //     color: rgba(var(--bs-secondary-rgb), 0.7);
  //     border: none;
  //     background-color: transparent;
  //     &:active,
  //     &:focus,
  //     &:hover {
  //       background-color: transparent;
  //       outline: none;
  //       border: none;
  //     }
  //   }
  //   & .download.btn:disabled {
  //     color: rgba(var(--bs-secondary-rgb), 0.3);
      
  //   }
  // }
  // .row {
  //   &:hover {
  //     & .openMonth, & .download {
  //       color: var(--bs-primary);
  //       &:hover {
  //         background-color: transparent;
  //         color: var(--bs-primary-variant-color);
  //         transition: linear 0.3s;
  //         & svg {
  //           scale: 1.1;
  //         }
  //       }
  //     }
  //     & .download.btn:disabled {
  //       color: rgba(var(--bs-secondary-rgb), 0.3);
  //       &:hover {
  //         & svg {
  //           scale: none;
  //         }
  //       }
  //     }
  //   }
    
  // }

  .StatusTotalsToggle {
    &.carousel {
      .carousel-control-prev, .carousel-control-next {
        color: transparent;
        // color: var(--selected-on-surface-light);
        display: none;

      }
      .carousel-control-prev {
        left: auto;
        right: 0;
        transform: scale(-1, 1);
      }
      .carousel-control-next {
        right: auto;
        left: 0;
        transform: scale(-1, 1);
      }
    }
  }

  @media (max-width: 400px) {
    // .openMonth {
    //     padding: var(--bs-btn-padding-y) 0; //calc(var(--bs-btn-padding-x)*0.5);
    // }
    .openMonthCol {
      padding-right: 0;
      padding-left: 0;
    }
    .status {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
