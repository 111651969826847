.HelpPageContent .HelpContent {
  margin-bottom: 10px;
  margin-top: 15px;
  text-align: justify;

  .accent {
    // font-weight: bold;
    color: var(--primary-color);
    // &.light {
    // font-weight: normal;
    // }
  }
  .accent-light {
    color: var(--text-secondary-on-background);
  }

  .inner-options {
    .ListItem {
      margin-top: 0;
    }
    .title {
      padding-left: 0;
    }
    .content {
      border-left: none !important;
      padding-left: 0 !important;
    }
  }

  .link {
    color: var(--primary-color);
  }

  .installPWA {
    width: 100%;
    max-width: 400px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.09rem;
    &:disabled {
      opacity: 0.5;
    }
  }

  ol,
  ul {
    padding-left: 1.5rem;
  }

  .hint {
    .TextOption {
      min-height: unset !important;
      padding-bottom: 5px !important;
      .title {
        min-height: unset !important;
        .arrow {
          // display: none;
          svg {

            padding-left: 0;
            margin-left: 0 !important;
          }
        }
        font-size: 0.85rem;
        font-weight: normal;
        text-decoration: underline;
        color: var(--primary-color);
        padding: 0;
        opacity: 0.5;
        background: transparent !important;
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .AccordionItemBody {
        padding-left: 0 !important;
      .content {
        padding-bottom: 1.0rem;
      }
    }
    }

    .Table {
      margin-bottom: 0;
    }
  }

  $sm-breakpoint: 576px;
  @media (max-width: $sm-breakpoint) {
    ol,
    ul {
      padding-left: 1rem;
    }
  }
}
