.MembersMonthlyTimesheetsTable .Header {
    .download.TooltipDropdownButton .TooltipDropdownItem {
        color: var(--primary-color);
    }

    @keyframes shrinkFont {
        from {
            font-size: 0.9rem;
            opacity: 0.5;
        }
        to {
            font-size: 0rem;
            opacity: 0;
            width: 0;
            padding: 0;
            border-width: 0;
        }
    }
    .TooltipButton, .TooltipDropdownButton,  .TooltipDropdownButton .dropdown-toggle {
        display: block;
        max-height: 32px;

    }
    
    .TooltipButton:disabled, .TooltipDropdownButton:has(.dropdown-toggle:disabled) {

        animation: shrinkFont 0.2s forwards;
    }
    .TooltipDropdownButton .dropdown-toggle:disabled {
        animation: shrinkFont 0.2s forwards;
    }

}