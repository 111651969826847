
@use "../../assets/styles/variables.scss" as global;

$tabbar-height-slim: 60px;
$tabbar-height-big: 70px;
// *:has(> .TabBar),
*:has(> .TabBar.slim) {
  padding-bottom: $tabbar-height-slim;
}
*:has(> .TabBar.big) {
  padding-bottom: $tabbar-height-big;
}
.TabBar {
  z-index: global.$z-index-fifth;
  position: fixed;
  bottom: 0;
  background-color: var(--background-color);
  padding-top: 10px;
  height: $tabbar-height-slim;

  &.big {
    height: $tabbar-height-big;
  }

  .Tabs {
    height: 100%;
    align-items: center;
    // justify-content: space-around;
    background-color: var(--surface-color);
    // background-color: var(--background-color);
    // color: var(--primary-color);
    box-shadow: var(--box-shadow);
    padding-bottom: 10px;

    .nav-link {
      height: 100%;
      color: var(--text-disabled-on-surface);
      border: none;

      &.main {
        // color: var(--secondary-color);
      }
      &.active {
        color: var(--primary-color);
        border: none;
      }
    }
  }
}
