.MembersTable {
  .active {
    color: var(--primary-color);
  }
  .non-active {
    opacity: 0.4;
  }
  .empty {
    color: var(--secondary-color);
  }

  .row {
    & .openDay {
      color: rgba(var(--bs-secondary-rgb), 0.7);
      border: none;
      background-color: transparent;
      &:active,
      &:focus,
      &:hover {
        background-color: transparent;
        outline: none;
        border: none;
      }
      &:disabled {
        color: transparent;
      }
    }
    &:hover {
      & .openDay:not([disabled]) {
        color: var(--bs-primary);
        &:hover {
          background-color: transparent;
          color: var(--bs-primary-variant-color);
          transition: linear 0.3s;
          & svg {
            scale: 1.1;
          }
        }
      }
    }
  }

  .FullTaskName2 .fn-categoryName {
    font-weight: normal;
  }
  .stop-timer {
    visibility: hidden;
  }
  // .is-active .active {
  //   color: inherit;
  // }
  .row.row-invalid {
    --Table-hover-background: rgba(
      var(--text-primary-invalid-on-surface-rgb),
      0.07
    );
    --Table-color: var(--text-primary-invalid-on-surface);
    --Table-color-variant-light: rgba(
      var(--text-primary-invalid-on-surface-rgb),
      0.85
    );
    // --Table-color-variant: var(--text-primary-invalid-on-surface);
    --Table-background: rgba(var(--text-primary-invalid-on-surface-rgb), 0.03);

    background-color: var(--Table-background);
    color: var(--Table-color);
    .is-active .active {
      color: var(--Table-color);
    }
    .stop-timer {
      visibility: visible;
      .ButtonScale {
        color: rgba(var(--text-secondary-invalid-on-surface-rgb), 0.5);
        &.parent-hovered {
          color: var(--Table-color-variant-light);
        }
        &:hover {
          color: var(--Table-color);
        }
      }
    }

    &:hover {
      background-color: var(--Table-hover-background);
      color: var(--Table-color);
    }
  }
  .row.row-warning {
    --Table-color: var(--text-primary-warning-on-surface);
    .is-active .active {
      color: var(--Table-color);
    }
    .total-time {
      color: var(--Table-color);
    }
  }

  .started-date {
    font-size: 0.62em;
    line-height: 0.7em;
    opacity: 0.5;
  }

  .mobile {
    .List > .Table > .Body > .row.ListItem .row {
      &:hover {
        background-color: var(--Table-hover-background);
      }
      min-height: 52px;
      // padding: 0.5rem;
    }
    .AccordionItem,
    .AccordionItemHeader {
      color: var(--text-primary-on-surface);
    }
    .AccordionItem .AccordionItemHeader.active {
      font-weight: bold;
    }
    .AccordionItem .AccordionItemBody > * {
      margin-bottom: 20px;
      padding-left: 10px;
    }
    .AccordionItem .AccordionItemBody .label {
      font-size: 0.85rem;
      color: var(--secondary-color);
    }
    .stop-timer {
      visibility: visible;
    }

    .row-warning .label {
      color: rgba(var(--text-primary-warning-on-surface-rgb), 0.7) !important;
    }
    .row-invalid {
      .label {
        color: rgba(var(--text-primary-invalid-on-surface-rgb), 0.5) !important;
      }
      .stop-timer  svg {
        color: rgba(var(--text-primary-invalid-on-surface-rgb), 1) !important;
      }
    }

    .openDay {
      $sm-breakpoint: 576px;
      @media (max-width: $sm-breakpoint) {
        padding-left: 5px;
        padding-right: 0;
      }
    }
    .started-date-mobile {
      color: var(--text-secondary-on-surface);
    }
  }
}
