@use "./theme_variables.scss" as theme;

@function rgb ($color) {
  @debug $color;
  @return "" + red($color) + ", " + green($color) + ", " + blue($color)+ "";
}

* {
  // common
  --btn-close-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");

  // light
  --primary-color: #{map-get(theme.$light-theme, "primary-color")};
  --primary-color-rgb: #{rgb(map-get(theme.$light-theme, "primary-color"))};
  --text-on-primary-color: #{map-get(theme.$light-theme, "text-on-primary-color")};
  // --text-on-primary-color-rgb: #{rgb(map-get(theme.$light-theme, "text-on-primary-color"))};

  --primary-variant-color: #{map-get(theme.$light-theme, "primary-variant-color")};
  --text-on-primary-variant-color: #{map-get(
    theme.$light-theme,
    "text-on-primary-variant-color"
  )};

  --primary-variant-light-color: #{map-get(theme.$light-theme, "primary-variant-light-color")};
  --primary-variant-darker-color: #{map-get(theme.$light-theme, "primary-variant-darker-color")};
  --primary-variant-darker-color-rgb: #{rgb(map-get(theme.$light-theme, "primary-variant-darker-color"))};

  --primary-variant-extra-dark-color: #{map-get(theme.$light-theme, "primary-variant-extra-dark-color")};
  --primary-variant-extra-dark-color-rgb: #{rgb(map-get(theme.$light-theme, "primary-variant-extra-dark-color"))};

  --secondary-color: #{map-get(theme.$light-theme, "secondary-color")};
  --secondary-color-rgb: #{rgb(map-get(theme.$light-theme, "secondary-color"))};
  --text-on-secondary-color: #{map-get(
    theme.$light-theme,
    "text-on-secondary-color"
  )};

  --background-color: #{map-get(theme.$light-theme, "background-color")};
  --background-color-rgb: #{rgb(map-get(theme.$light-theme, "background-color"))};
  --background-variant-color: #{map-get(theme.$light-theme, "background-variant-color")};
  --text-primary-on-background: #{map-get(
    theme.$light-theme,
    "text-primary-on-background"
  )};
  --text-secondary-on-background: #{map-get(
    theme.$light-theme,
    "text-secondary-on-background"
  )};
  // --text-hint-on-background:;
  // --text-disabled-on-background:;
  // --text-icon-on-background:;

  --surface-color: #{map-get(theme.$light-theme, "surface-color")};
  --text-primary-on-surface: #{map-get(
    theme.$light-theme,
    "text-primary-on-surface"
  )};
  --text-secondary-on-surface: #{map-get(
    theme.$light-theme,
    "text-secondary-on-surface"
  )};
  --text-secondary-on-surface-rgb: #{rgb(map-get(
    theme.$light-theme,
    "text-secondary-on-surface"
  ))};
  --text-disabled-on-surface: #{map-get(
    theme.$light-theme,
    "text-disabled-on-surface"
  )};
  --text-disabled-on-surface-rgb: #{rgb(map-get(
    theme.$light-theme,
    "text-disabled-on-surface"
  ))};
  --text-primary-invalid-on-surface: #{map-get(theme.$light-theme, "text-primary-invalid-on-surface")};
  --text-primary-invalid-on-surface-rgb: #{rgb(map-get(theme.$light-theme, "text-primary-invalid-on-surface"))};
  --text-secondary-invalid-on-surface: #{map-get(theme.$light-theme, "text-secondary-invalid-on-surface")};
  --text-secondary-invalid-on-surface-rgb: #{rgb(map-get(theme.$light-theme, "text-secondary-invalid-on-surface"))};

  --text-primary-warning-on-surface: #{map-get(theme.$light-theme, "text-primary-warning-on-surface")};
  --text-primary-warning-on-surface-rgb: #{rgb(map-get(theme.$light-theme, "text-primary-warning-on-surface"))};

  --hover-on-surface-lighter: #{map-get(theme.$light-theme, "hover-on-surface-lighter")};
  --hover-on-surface-light: #{map-get(theme.$light-theme, "hover-on-surface-light")};
  --hover-on-surface-dark: #{map-get(theme.$light-theme, "hover-on-surface-dark")};

  --selected-on-surface-light: #{map-get(theme.$light-theme, "selected-on-surface-light")};

  --border-color: #{map-get(theme.$light-theme, "border-color")};
  --border-color-dark: #{map-get(theme.$light-theme, "border-color-dark")};

  --backdrop-color: #{map-get(theme.$light-theme, "backdrop-color")};
  --box-shadow-color: #{map-get(theme.$light-theme, "box-shadow-color")};
  --box-shadow: #{map-get(theme.$light-theme, "box-shadow")};
  --box-border-on-surface: #{map-get(theme.$light-theme, "box-border-on-surface")};

  --emphasis-color: #{map-get(theme.$light-theme, "emphasis-color")};
  --text-on-emphasis-color: #{map-get(theme.$light-theme, "text-on-emphasis-color")};

  // --attention-hight-text-color: #{map-get(theme.$light-theme, "attention-hight-text-color")};
  // --attention-hight-text-color-rgb: #{rgb(map-get(theme.$light-theme, "attention-hight-text-color"))};
  // --attention-medium-text-color: #{map-get(theme.$light-theme, "attention-medium-text-color")};
  // --attention-medium-text-color-rgb: #{rgb(map-get(theme.$light-theme, "attention-medium-text-color"))};
}


.dark-theme * {
  --primary-color: #{map-get(theme.$dark-theme, "primary-color")};
  --text-on-primary-color: #{map-get(theme.$dark-theme, "text-on-primary-color")};

  --primary-variant-color: #{map-get(theme.$dark-theme, "primary-variant-color")};
  --text-on-primary-variant-color: map-get(
    theme.$dark-theme,
    "text-on-primary-variant-color"
  );

  --secondary-color: #{map-get(theme.$dark-theme, "secondary-color")};
  --text-on-secondary-color: map-get(
    theme.$dark-theme,
    "text-on-secondary-color"
  );

  --background-color: #{map-get(theme.$dark-theme, "background-color")};
  --text-primary-on-background: map-get(
    theme.$dark-theme,
    "text-primary-on-background"
  );
  --text-secondary-on-background: map-get(
    theme.$dark-theme,
    "text-secondary-on-background"
  );
  // --text-hint-on-background:;
  // --text-disabled-on-background:;
  // --text-icon-on-background:;

  --surface-color: #{map-get(theme.$dark-theme, "surface-color")};
  --text-primary-on-surface: map-get(
    theme.$dark-theme,
    "text-primary-on-surface"
  );
  --text-secondary-on-surface: map-get(
    theme.$dark-theme,
    "text-secondary-on-surface"
  );

  --backdrop-color: #{map-get(theme.$dark-theme, "backdrop-color")};
  --box-shadow: #{map-get(theme.$dark-theme, "box-shadow")};
}
