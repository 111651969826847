.Combobox {
    .dropdown-menu {
        background-color: var(--surface-color);
        box-shadow: 0px 3px 3px 0px rgba(var(--secondary-color-rgb), 0.3);
        border: 1px solid var(--border-color);
        width: 100%!important;
        margin-top: 3px;
        .dropdown-item {
            color: var(--text-primary-on-surface);
            &:hover {
                background-color: var(--hover-on-surface-light);
            }
            &:active, &.active {
                background-color: var(--selected-on-surface-light);
            }
        }

    }
    // .rbt-aux {
    //     float: right;
    //     box-sizing: content-box;
    //     // width: 0.2em;
    //     // height: 0.2em;
    //     padding: 0.25em;
    //     color: var(--text-secondary-on-surface);
    //     // opacity: 0.2;
    //     // background: transparent var(--btn-close-bg) center/1em auto no-repeat;
    //     cursor: pointer;
    //     .btn-close {
    //         color: var(--text-secondary-on-surface);
    //         color: red;
    //     }
    // }
}