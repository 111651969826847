.MonthsTable {
    .row {
        & .openMonth {
            color: rgba(var(--bs-secondary-rgb), 0.7);
            border: none;
            background-color: transparent;
            &:active, &:focus, &:hover {
                background-color: transparent;
                outline: none;
                border: none;
            }
        }
    }
    .row {
        &:hover {
            & .openMonth {
                color: var(--bs-primary);
                &:hover {
                    background-color: transparent;
                    color: var(--bs-primary-variant-color);
                    transition: linear .3s;
                    & svg {
                        scale: 1.1;
                    }
                }
            }
        }
    }
}