.Accordion .AccordionItem {
    &.card {
        border: none;
        background-color: transparent;
        & .card-header {
            background-color: transparent;
            padding: 0;
        }
        & .card-body {
            background-color: transparent;
            padding: 0;
        }
    }
}