.TasksList {
    // min-width: 300px;

    &.Card .Grid .Node {
        overflow: auto;
    }

    .WidgetCardBody {
        padding-top: 0;
    }
}