.DayStatusRequested {
    max-width: 100%;
    &.small {
        .dropdown-toggle {
          padding: 0.1rem 0.4rem;
          font-size: 0.8rem;
        }  
      }
      &.disabled {
          .dropdown-toggle {
              opacity: var(--bs-btn-disabled-opacity);
          }
      }
      .dropdown-toggle {
        white-space: normal;
        // padding: 0.1rem 0.4rem;
        // font-size: 0.8rem;
        color: var(--primary-color);
        // color: rgba(var(--bs-primary-rgb), 0.5);
        // --bs-primary-rgb
        // border:  rgba(var(--bs-primary-rgb), 0.4);
        // opacity: var(--bs-btn-disabled-opacity);
        background-color: transparent;

        // &:active, 
        &:hover
        // &:focus 
        {
            background-color: transparent;
            color: var(--primary-variant-color);
            border-color: rgba(var(--primary-color-rgb), 0.8);

            // opacity: 1;
        }
        // &:focus {
        //     background-color: transparent;
        //     color: var(--primary-color);
        // }
    }
    .dropdown-toggle::after {
        display: block;
    }
    .dropdown-toggle::before, .dropdown-toggle::after  {
        content:none !important;
    }
    .dropdown-menu {
        // padding: 0.25rem 0;
        min-width: 5rem;
        .dropdown-item {
            // padding: 0.25rem 0.5rem;
            text-align: center;
            // font-size: 0.9rem;
            line-height: 1;
            // &:active {
            //     // background-color: var(--hover-on-surface-light);
            // }
        }
    } 
    & > .dropdown-toggle {
        color: var(--text-secondary-invalid-on-surface);
        border-color: rgba(var(--text-secondary-invalid-on-surface-rgb), 0.6);
        &:hover, &:active {
            color: var(--text-primary-invalid-on-surface);
            border-color: rgba(var(--text-primary-invalid-on-surface-rgb), 0.8);
        }
    }
}