.TimeHoursMinutes {
    .tit {
        // fontSize: "0.85em", opacity: "0.6"
        font-size: 0.75rem;
    }
    .numbers {
        // font-size: 1.05rem;
        font-size: 1rem;
    }
    &.letters-light {
        .tit {
            opacity: 0.7;
        }
    }
    &.numbers-bold {
        .numbers {
            font-weight: bold;
        }
    }
    &.larger {
        .tit {
        font-size: 0.85rem;
        }
        .numbers {
            font-size: 1.05rem;
        }
    }
}